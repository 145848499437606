.dc {
	//--- Override the product card padding
	&.page-products .products-grid .product-item {
		margin-left:0;

	}
	.product-items .action.towishlist:hover:before,
	.products.list.items .action.towishlist:hover:before,
	.product-items .action.towishlist:before,
	.products.list.items .action.towishlist:before  {
		color:#ffffff;
	}
	.product-image-container {
		width:100%;

        img {
            min-height: 215px;
            object-fit: cover;
        }
	}
	.products-wrapper {

		margin-bottom:80px;
		position:relative;
        margin-top: 24px;

        padding-bottom: 3em;

		@media screen and (min-width:768px) and (max-width:991px){
			margin-right:-16px;
		}

        @media screen and (min-width:992px){
            margin-top: 4em;
            margin-right: 1em;
            margin-left: 1em;
            padding-bottom: 0em;
        }

        .glider {
            @media screen and (min-width:992px){
                justify-content: center;
            }
        }

		.glider-dots {
			margin-top:41px;
			@media screen and (min-width:992px){
			display:none;
			}
			button {
				position:relative;
				background-color:$gold;
				height:4px;
				width:24px;
				@media screen and (min-width:992px){
					height:4px;
					width:40px;
				}
				border-radius:0;
				opacity:0.4;
				&.active {
					opacity:1;
				}

			}
		}

        #products_carousel_counter {
            position: absolute;
            bottom: -0.5em;
            color: #000;
            font-size: 40px;
            display: flex;
            font-family: "acumin-pro", Arial;
            left: 50%;
            transform: translateX(-50%);

            font-size: 12px;
            line-height: 16px;

            @media screen and (min-width:992px){
                display: none;
            }

            #products_slideCounterCurrent {

                padding-right: 32px;
                position: relative;

                &:after {
                    content: ' ';
                    width: 16px;
                    height: 1px;
                    background: #000;
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    opacity: .25;
                }
            }

            #products_slideCounterTotal {
                opacity: .25;
            }
        }

		button {
			position:absolute;
			z-index:1;
			background-repeat:no-repeat;
			background-size:40px 80px;
			background-position:center center;
			font-size:1px;
			border:none;
            height: 40px;
            background: #fff;
            border: 1px solid #CFB67D80;
            border-radius: 40px;
            bottom: -10px;

			&.next {

                background: url(/pub/media/theme/arrow_right_black.svg?2024) #ffffff;
                background-position: 50%;
                background-repeat: no-repeat;

				right:0px;
				display:block;
				min-width:40px;
                bottom: -20px;
				@media screen and (min-width:992px){
					display:none;
				}
			}
			&.prev {
				left:0px;
                background: url(/pub/media/theme/arrow_left_black.svg?2024) #ffffff;
                background-position: 50%;
                background-repeat: no-repeat;
				display:block;
				min-width:40px;
                bottom: -20px;
				@media screen and (min-width:992px){
					display:none;
				}

			}
		}
	}
	div[class*="fc-products-block"] {
		//TABLET RULES
		@media screen and (min-width:510px) and (max-width:991px){
			.product {
				padding-left:22px;
				&:first-child {
					margin-left:-22px;
				}
			}
		}

		@media screen and (min-width:510px) and (max-width:767px){
			padding:0 !important;
			// margin:0 -24px 0 0 !important;
			width: calc(100vw - 30px);
			overflow:hidden;
			.products-wrapper {
				margin:0 -24px 80px 0 !important;
			}
			.glider {
				.product {
					button {
						left: calc(50% + 12px);
						&.next {
							right:36px;
						}
					}
				}
			}
			.products-wrapper button {
					&.next {
						right:24px;
					}
				}
		}

		@media screen and (min-width:768px) and (max-width:991px){
			padding:0 !important;
			margin:0 7px 0 15px !important;
			.glider {
				.product {
					button {
						left: calc(50% + 12px);
						&.next {
							right:24px;
						}
					}
				}
			}
			.products-wrapper button {
					&.next {
						right:24px;
					}
				}
		}
	}

	.product {
		margin-right:0;
		@media screen and (min-width:510px) and (max-width:767px){
			//margin-right:24px;

			margin-right:0;
			// padding-left:24px;


			&:nth-child(2n){
			//	margin-right:0;
			}
		}
		@media screen and (min-width:768px) and (max-width:991px){
			margin-right:0;
			// padding-left:24px;

			&:nth-child(3n){
				// margin-right:0;
			}

		}
		@media screen and (min-width:992px) {
			margin-right:24px;
		}
		padding-bottom:1px;
		position:relative;
		&:nth-child(4n){
			margin-right:0;
		}
		&.product-item {
			margin-left:0;
		}
		> .product {
			margin-right:0;
			margin-left:0;
			height:100%;
		}

		a.product-image-img {
			display:block;
		}

		.dc-hotel {
            text-align:center;
            text-transform:uppercase;


            font-family: "acumin-pro", Arial;
            font-size: 12px;
            line-height: 16px;
            font-weight: 300;
            color: #A18D61;

            margin-bottom: 1em;

		}
		.dc-name {
            margin-bottom:16px;

			@include MobileH4BlackCentre;
            font-family: "acumin-pro", Arial;
            font-size: 24px;
            line-height: 26px;
            font-weight: 300;
            max-width: 90%;
            margin: 0 auto 1em;

			@media screen and (min-width:992px){
                min-height: 60px;
                //padding: 0 1em;
                max-width: none;
			}

		}

		&.product-item .product .price-box .price,
		&.product-item .product .price-box .price-label,
		.dc-price {

            margin-bottom: 70px;
            text-align:center;

            font-family: "acumin-pro", Arial;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;

            .promo_text_product {
                display:none!important;
            }
		}

        .price {

            font-weight: 400;

            @media screen and (min-width:768px){
                font-size: 16px;
            }
        }

		&.product-item .product .price-box .price-label:after{
			content:'';
		}

		img {
			display:block;
			border:1px solid $light-grey;
			width:100%;
			margin-bottom:24px;
			&.amasty-label-image{
				border:none;
			}
		}

		button {
			position:absolute;
			bottom:0;
			left:50%;
			transform:translateX(-50%);

			&.action.primary{

                border-radius:0;
                margin: 0 auto 2px;
                display: block;
                background: $gold;
                border-radius: 42px;
                transition: 0.3s ease-in;

				&:hover {
					//background: $tan;
					//color:$white;
					span {
						color:$white;
					}
				}
				span {
					font-size: 14px;
                    min-width: 140px;
                    color: $white;
                    padding: 8px 24px;

                    @media screen and (min-width:768px){
                        font-size: 12px;
                    }
				}
			}
		}
	}

	.product-block {
		ol {
			li {
				.product {
					.product-image-container {
						width: 100%;
						height: 188px;
						display: block;
						position: relative;
						margin-bottom: 24px;

						img {
							display: block;
							border: 1px solid #dddddd;
							width: 100%;
							margin-bottom: 24px;
							object-fit: cover;
							position: absolute;
						}
					}
				}
			}
		}

		&.product-block-small {
			ol {
				justify-content: center;
			}
		}
	}
}
