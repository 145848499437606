.dc {
	.modal-popup {
		@include DesktopBody-Copy---MediumDemiBlackLeft;
		button:not(.action-close) {
			border:1px solid $gold;
			border-radius:0;
			background:$white;
			@include DesktopBody-Copy---SmallRomanBlackCentre;
			height: 40px;
				border-radius: 0;
				box-shadow: none;
			&:last-child {
				background:$gold;
				@include DesktopBody-Copy---SmallRomanWhiteCentre;
				&:hover {
					background:$tan;
				}
			}
		}
		button.action-save-address {
			background: $gold;
		}

        &.agreements-modal {
            button:not(.action-close) {
                &:last-child {
                    background:$gold;
                    @include DesktopBody-Copy---SmallRomanWhiteCentre;
                    color: #fff;
                    &:hover {
                        background:$tan;
                    }
                }
            }
        }
	}
	#confirmBox {
		    left: auto;
		    right: auto;
		    margin: 0 auto;
			border-radius: 0;

			.ampage-top-info {
				margin-top:0;
			}
			.am-title {
				@include DesktopH4BlackLeft;
				margin-top:0;

			}
			.amcart-product-notice {
				background:$white;
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				text-align:center;
			}
			.amcart-minipage-wrap .amcart-confirm-buttons .button{
				@include DesktopBody-Copy---SmallRomanWhiteCentre;
				height: 40px;
				max-weight:44px;
				border-radius: 0;
				border-bottom: 0;
				box-shadow: none;
				@include DesktopBody-Copy---SmallRomanWhiteCentre;
			}
			.amcart-confirm-buttons .am-btn-left {
				background:transparent;
				color:$black;
				@include DesktopBody-Copy---MediumRomanBlackLeft;
				text-decoration:underline;
			}
			.amcart-confirm-buttons .am-btn-left,
			.amcart-minipage-wrap .amcart-confirm-buttons .button,
			.amcart-minipage-wrap .amcart-confirm-buttons .am-btn-left,
			.amcart-minipage-wrap .amcart-confirm-buttons .am-btn-right {
				height: 40px;
				max-weight:44px;
				border-radius: 0;
				border-bottom: 0;
				box-shadow: none;
			}
	}

	.amcart-minipage-wrap .amcart-confirm-buttons .am-btn-left,
	.amcart-minipage-wrap .amcart-confirm-buttons .am-btn-right {
		height: 40px;
		max-weight:44px;
		border-radius: 0;
		border-bottom: 0;
		box-shadow: none;
	}

}

.modal-popup._show .modal-inner-wrap {
    transform: translateY(-50%);
    top: 50%;

    button {
        border-radius: 24px;

        &.action-accept {
            color: #fff;
            min-width: 80px;
        }
    }
}
