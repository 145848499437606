.dc {

    .product-image-wrapper {
        img {
            @media screen and (min-width:992px){
                max-height: 92px;
                min-height: 0!important;
            }
        }
    }

		.minicart-items .update-cart-item{
            font-size: 1.1rem;
            vertical-align: top;
            border: 1px solid $gold;
            border-radius: 0;
            background: white;
            color: black;
            display: inline-block;
            left: 42px !important;
            position: relative !important;
            top: 5px;
            @include DesktopBody-Copy---SmallRomanBlackCentre;
		}
		.block-minicart {
			a {
				color:$black !important;
			}
			.block-title {
				display:block;
				strong {
					> span {
						display:none;
					}

				}
			}
			.block-content {
				strong.subtitle:not(.empty) {
					width: 100%;
				    left: 0;
				    background: transparent;
					position:absolute;
					top:24px;
					font-style: normal;
					pointer-events:none;
				}
			}
		}
		.minicart-wrapper {
			z-index:10;
			.action.close{
				top:12px;
			}
			.action.showcart .counter.qty{
				text-align: right;
				right: 30px;
                color: #000;
			}
			> * {
				color:$black;
				@include DesktopBody-Copy---SmallDemiBlackLeft;
				font-weight:normal;
			}

			strong {
				&.subtitle {
					@include DesktopH7GoldCentre;
					text-transform:uppercase;
					font-weight:normal;
				}
			}

			#top-cart-btn-checkout {
				@include sentence-case();
				position: absolute;
				bottom: 0;
				left: 15px;
				max-width: calc(50% - 16px);
				@include DesktopBody-Copy---SmallRomanWhiteCentre;
                color: #fff;
				font-weight:normal;
                height: 42px;
                line-height: 33px;
                padding:0;
                border-radius: 42px;
			}
			.block-minicart {
				margin-top:13px;
				@media screen and (max-width:991px){
					right: -15px;
					left: -15px;
					width: 100vw;
				}
				@media screen and (min-width:992px){
                    margin-top: 12px;
				}
				padding:60px 16px 16px;
				border:none;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
				background-color: $white;
				select.item-qty.cart-item-qty,
				input.item-qty.cart-item-qty {
					background-color:$white;
					border:1px solid $light-grey;
					width:auto;
					max-width:82px;
                    border-radius: 40px;
					&[type="number"]:not(#search){
						max-width:82px;
						text-indent:0;
					}
					&:focus {
						width:auto;
						max-width:auto;
					}
				}
				select.item-qty.cart-item-qty {
					width:82px;
					min-width:82px;
				}
				.product {
					.actions {
						position:absolute;
						bottom:0;
						right:0;
					}
					.action {
						&.edit,
						&.delete {
							> span {
								border: 0;
							    clip: rect(0, 0, 0, 0);
							    height: 1px;
							    margin: 0 0 0 10px;
							    overflow: hidden;
							    padding: 0;
							    position: relative;
							    width: auto;
							    color: #000;
							    margin-left: 10px;
							    text-decoration: underline;
							}
							&::before {
								content:'';
							}
						}
					}
				}
				.product-item-details {
					position:relative;
					padding-right: 98px;
					@media screen and (min-width:992px){
						padding-left: 98px;
					}
				}
				.product-item-pricing .price-container {
					@media screen and (min-width:992px){
					position: absolute;
					}
				    top: -6px;
				    right: 0;
				}
				.subtotal {
					margin-left:0;
					margin-right:0;
					span {
						@include DesktopBody-Copy---SmallRomanBlackRight;
						font-weight:normal;
						&.price {
							@include DesktopBody-Copy---MediumDemiBlackRight;
							font-weight:normal;
						}
						&:after {
							content:'';
						}
					}
				}
				a{
					&.action.viewcart{
						@include sentence-case();
						@include DesktopBody-Copy---SmallRomanBlackCentre;
						border: 1px solid $gold;
						width: calc(50% - 16px);
						display: block;
						height: 42px;
						margin-left: calc(50% + 16px);

                        border-radius: 42px;
                        line-height: 38px;
                        padding: 0;

						&:hover {
							background:$gold;
							text-decoration:none;
							span {
								color:$white;
							}
						}
					}
				}
				&:before,
				&:after {
					display:none;
				}
				.product.options {
					display:none;
				}
				.items-total {
					@include DesktopBody-Copy---MediumDemiBlackLeft;
					font-weight:normal;
					padding-top:21px;
					span {
						@include DesktopBody-Copy---MediumDemiBlackLeft;
						font-weight:normal;
					}
				}
				.details-qty.qty {
					label {
						display:none;
					}
				}
				.minicart-items{
					.product-item-details .price{
						font-weight:normal;
					}
					.product-item:not(:first-child) {
						border-top:1px solid $light-grey;
						margin-top:24px;
					}

                    .product-image-container {
                        img {
                            min-height: 0;
                        }

                    }

				}
				.minicart-items-wrapper {
					border:1px solid $light-grey;
					border-left:0;
					border-right:0;
					margin:0;
					padding-left:0;
					padding-right:0;
					clear:both;
					min-height: 140px;

				}
			}
		}
	//}

}
