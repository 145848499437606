.dc {
	.category {
		flex-basis:calc(100%);

        @media screen and (min-width:768px){
            flex-basis:calc((100% / 2) - 16px);
            margin-right:32px;
            margin-bottom:24px;
        }

		&:nth-child(2n){
			margin-right:0;
		}
		.title {

            font-family: "acumin-pro", Arial;
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 30px;
            text-align: center;
			margin-top: 10px;

			@media screen and (min-width:992px){
                font-size: 24px;
                font-weight: 300;
                margin-bottom: 40px;
                margin-top:24px;
			}

			a {
				color:inherit;
                font-weight: 300;
			}
		}
		img {
			width:100%;
			height:auto;
			border: solid 1px $light-grey;
			display:block;

            @media screen and (max-width:768px) {
                max-height: 180px;
                object-fit: cover;
            }

			a {
				display:block;
			}
		}
		&-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: left;
			margin-left:-15px;
			margin-right:-15px;
			margin-bottom:40px;
			@media screen and (min-width:768px){
				padding: 0;
				margin-left:0;
				margin-right:0;
			}
			@media screen and (min-width:992px){

                max-width: 1128px;
                margin: 0 auto 80px;
			}
		}

	}
}
