body.dc {
	.action.print,
	#cart-sidebar-reorder,
	.block-reorder {
		display:none;
	}
	&.account {
		@media screen and (min-width:510px) and (max-width:768px){
			.main {
				flex-basis:calc(70% - 32px);
				flex-grow: unset;
				order:2;
			}
			.sidebar-main {
				flex-basis:30%;
				flex-grow: unset;
			}
		}

		.fieldset .fullname .field .label {
			width: 100%;
			text-align: left;
		}

	}
	.message.success > *:first-child:before {
		content:'' !important;
		margin:0;
		background-image:none;
	}
	.message {
		font-family: "acumin-pro", Arial;
		font-size:14px;
		font-weight:normal;
		padding-left:0;
		background:transparent;
		span {
			&:before {
				content:'' !important;
				margin:0;
				background-image:none;
			}
		}
	}
	&.account,
	&[class*="customer-account-forgotpassword"],
	&[class*="customer-account-create"] {
		#maincontent {
			padding-top:0;
		}
		#account-nav {
			padding-top:0;
		}
		.breadcrumbs {
				text-align:left;
			}
		.actions-toolbar {
			.action {
				font-size:14px;
			}
		}
		&[class*="sales-order"],
		&[class*="customer-address"],
		&[class*="vault-cards"],
		&[class*="newsletter"]{

			#maincontent {
				// margin-top:40px;

			}
		}
		.block-content {
			@include DesktopBody-Copy---SmallRomanBlackLeft;
		}
		.payment-method {
			.title {
				@include DesktopBody-Copy---SmallRomanBlackLeft;
				text-align: left;
    			margin: 0;
			}
			td,th {
				@include DesktopBody-Copy---SmallRomanBlackLeft;
				font-size:14px !important;
				padding:0;
				.price-including-tax .price,
				.price-excluding-tax .price,
				.items-qty .title {
					@include DesktopBody-Copy---SmallRomanBlackLeft;
				}
			}
		}
		address,
		.box-title + .box-content {
			@include DesktopBody-Copy---SmallRomanBlackLeft;
		}
		.block-order-details-view {
			margin-top:80px;
		}
		.order-links {
			display:none;
		}
		.table-wrapper .data {
			&.table {
				th {
					  @include DesktopBody-Copy---SmallDemiBlackLeft;
					  background:$light-grey;
				}
				tfoot {
					th {
						background:$white;
					}
				}
				th, td {
					padding:7px 9px;
				}
				td {
					a {
						@include DesktopBody-Copy---SmallRomanBlackLeft;
					}
					 @include DesktopBody-Copy---SmallRomanBlackLeft;
					 .price-including-tax .price,
					 .price-excluding-tax .price,
					 .items-qty .title {
						 @include DesktopBody-Copy---SmallRomanBlackLeft;
					 }

				}
			}
		}
		#maincontent .page-title-wrapper {
				display: flex;
				justify-content: center; /* centers child */
				position:relative;
				margin-bottom: 30px;
				@media screen and (min-width:992px){
					margin-bottom: 40px;
				}
				&:before {
					content:'';
					height:1px;
					background:$gold;
					width:100%;
					position:absolute;
					top:calc(50% - 0.5px);
					left:0; right:0;

				}

					.order-status {
						left:22.3%;
						&:before {
							@include DesktopBody-Copy---SmallRomanBlackLeft;
							content:'Status: ';
							display:inline-block;
							font-weight:700;
							margin-right:4px;

						}
					}
					.order-actions-toolbar {
						right:0;
					}
					.order-status,
					.order-date,
					.order-actions-toolbar {
						@include DesktopBody-Copy---SmallRomanBlackLeft;
						.label {
							@include DesktopBody-Copy---SmallRomanBlackLeft;
							font-weight:700;

						}
						margin:0;
						top:50px;
						position:absolute;
						color:$black;
						a {
						@include a;
						}
					}

				.page-title {
					@include MobileH2BlackCentre;
					padding:0 16px;
					@media screen and (max-width:410px){
						max-width:280px;
					}
					 @media screen and (min-width:992px){

						@include DesktopH2BlackCentre;
						padding:0 40px;
					}
					margin:0;
					position:relative;
					display: block;
					background:$white;

				}
			}
		.main {
			@media screen and (max-width:768px){
			margin: 0 16px;
			}

			@include DesktopBody-Copy---MediumRomanBlackCentre;
			div[class*="-order"].block {
				text-align:left;
				& > .block-title,
				& > .order-title {
					@include DesktopH7BlackLeft;
					strong {
						@include DesktopH7BlackLeft;
						text-transform:uppercase;
						+ .action {
							font-size:14px;
							text-transform:none;
							span {
								text-transform:none;
							}
						}
					}
				}
			}
			.order-title {
				@include DesktopH7BlackLeft;
					strong {
						@include DesktopH7BlackLeft;
						text-transform:uppercase;
					}
			}
			.order-items {
				+ .actions-toolbar {
					display:none;
					.action.back {
						border:1px solid $gold;
						padding:7px 9px;

					}
				}
			}
			.order-actions-toolbar {
				.actions {
					color:$black;
					a {
					@include a;
					}
				}
			}


			div,
			.breadcrumbs {
				text-align:left;
			}
			.fieldset > .field.choice:before,
			.fieldset > .field.no-label:before {
				display:none;
			}
			.additional{
				margin-bottom:40px;
			}
			.actions-toolbar{
				.primary{

					@media screen and (min-width:769px){
						+ .secondary {
							margin-left:80px;
						}
					}

				}
				.secondary {
					float:left;
				}
			}
			.fieldset > .field {

				&:not(.choice){
					@include DesktopBody-Copy---MediumDemiBlackLeft;
					span {
						@include DesktopBody-Copy---MediumDemiBlackLeft;

						//@include sentence-case;

					}
					& > .label {
						float:none;
						margin-bottom:4px;
						text-align:left;
					}
					&.required > .label {
						float:none;
						margin-bottom:4px;
						text-align:left;
						&:after {
							content:' (required)';
							font-weight:normal;
							display: inline;
							@include DesktopBody-Copy---MediumDemiBlackLeft;
							margin-left: 0;
						}
					}
					& > .control {
						max-width: 362px;
						float:none;
					}
				}
				margin-bottom:16px;

				span {
					@include DesktopBody-Copy---MediumRomanBlackLeft;
				}
			}
			.legend,
			.block:not(.widget) .block-title,
			.block:not(.widget) .block-title strong {
				@include DesktopH7BlackLeft;
				text-transform:uppercase;
				strong {
					@include DesktopH7BlackLeft;
					text-transform:uppercase;
                    font-size: 300;
					+ .action {
						font-size:14px;
						text-transform:none;
						span {
							text-transform:none;
						}
					}
				}
			}
			a {
				@include DesktopBody-Copy---MediumRomanBlackCentre;
			}
			.box-actions {
				margin-top:16px;
				.action {
					@include DesktopBody-Copy---MediumRomanBlackLeft;
					margin-right:16px;
					display:inline-block;
					font-size:14px;
					text-decoration: underline;

					&:hover {
						color:$gold;
					}

					a {
						&:hover {
							color:$gold;
							margin-right:16px;
						}
					}
				}
				a {

				}
			}
			label {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				span {
					@include DesktopBody-Copy---MediumRomanBlackLeft;
					margin-right:4px;
				}
			}input[type='checkbox'] {
				appearance:none;
				width:0;
			}
			input[type='checkbox'] + label:before {

				width:24px;
				height:24px;
				content:'';
				display:inline-block;
				background-repeat:no-repeat;
				background-position:center center;
				position:relative;
				top:6px;
				margin-right:8px;
				border:1px solid $light-grey;
			}
			input[type='checkbox']:checked + label:before {
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23000000'/%3E%3Cpath d='M5 10l4 4 6-7' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
				background-color:$black !important;
			}
			.toolbar .limiter-options {
				min-width:80px;
			}
		}
		.sidebar {
			&-main,
			&-additional {
				display:block;
				.delimiter {
					display:none;
				}
					.block {
						text-align:left;
						& > .block-title {
							@include DesktopH7BlackLeft;
							strong {
								@include DesktopH7BlackLeft;
								text-transform:uppercase;
							}
						}
					}
					a {
						@include a;
						text-decoration:none;
						&:hover {
							text-decoration:underline;
						}
					}
					.legend {
						@include DesktopH4BlackLeft;
						font-weight:normal
					}
					.account-nav .content {
						background:$white;
						background-color:$white;
						@include DesktopBody-Copy---MediumRomanBlackCentre;
						.nav,li,a {
							text-align:left;
							&.current {
								a {
									border-color:$white;
									strong {
										border-color:$white;
									}
								}
								border-color:$white;
								strong {
									border-color:$white;
								}
							}
							&:hover {
								background-color:transparent;
								background:transparent;
							}
						}
						a {
							@include DesktopBody-Copy---MediumRomanBlackLeft;
							text-align:left;
							&:hover {
								color:$gold;
							}
						}
					}
			}
		}
	}
	&[class*="customer-account"] {
		.page-main {
			padding:0px 0;
			@include DesktopBody-Copy---SmallRomanBlackCentre;
			.fieldset > .field:not(.choice) > .label {
				text-align:left;
				font-weight:normal;
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				margin-bottom:4px;
				width:100%;
			}
			.fieldset > .field.required > .label:after,
			.fieldset > .fields > .field.required > .label:after,
			.fieldset > .field._required > .label:after,
			.fieldset > .fields > .field._required > .label:after {
				content:'(required)';
				font-weight:normal;
				@include DesktopBody-Copy---MediumDemiBlackLeft;
			}
			input:not(.checkbox){
				min-height:40px;
				max-width:392px;
				width:100%;
				line-height:40px;
				text-indent:8px;
				border:1px solid $light-grey;
			}
			.note {
				text-align:center;
			}
			.page-title-wrapper {
				display: flex;
				justify-content: center; /* centers child */
				position:relative;
				margin-bottom: 30px;
				@media screen and (min-width:992px){
					margin-bottom: 40px;
				}
				&:before {
					content:'';
					height:1px;
					background:$gold;
					width:100%;
					position:absolute;
					top:calc(50% - 0.5px);
					left:0; right:0;

				}
				.page-title {
					@include MobileH2BlackCentre;
                    font-family: "acumin-pro", Arial;
                    font-style: normal;
					padding:0 16px;
					@media screen and (max-width:410px){
						max-width:280px;
					}
					@media screen and (min-width:992px){
						@include DesktopH2BlackCentre;
						padding:0 40px;
					}
					margin:0;
					position:relative;
					display: block;
					background:$white;

				}
			}


		}
		div[class*="-container"]{
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
			.fieldset:after {
				display:none;
			}
			a.action:not(.create) {
				@include a;
				color:$black;
				@include DesktopBody-Copy---SmallRomanBlackCentre;
				text-decoration:underline;
				display:block;
				width:100%;
				display: block;
			    clear: left;
			    text-align: left;
			    padding-top: 16px;
			}
			.block-title {
                font-family: "acumin-pro", Arial;
				font-size: 32px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.38;
				letter-spacing: normal;
				text-align: center;
				color: $black;
				border-bottom:0;
			}
			div[class*="block-customer"],
			div[class*="block-new-"] {
				max-width:460px;
				width:100%;
				@media screen and (min-width:992px){
					flex-basis:calc(50% - 22px);
				}
				border:1px solid $gold;
				padding:34px;
				margin-bottom: 0;
				.fieldset > .field,
				.fieldset > .fields > .field {
					text-align:left;
				}
				&:first-child {
					margin-bottom:80px;
					@media screen and (min-width:992px){
						margin-right:44px;
						margin-bottom:0;
					}

				}
				&:last-child {
					margin-right:0;
				}
				.block-content {
					text-align:left;
				}
			}
		}
	}

	&.customer-account-logoutsuccess {
		.page-main {
			padding-top: 4em;
		}
	}

	.nav-toggle:before {
		color:#000 !important;
	}

	.page-main > .page-title-wrapper .page-title {
		@include DesktopH4BlackCentre;
        font-family: "acumin-pro", Arial;
        font-weight: 300;
		margin-top:40px;
		margin-bottom:40px;

		@media screen and (min-width:992px){
		    @include DesktopH1BlackCentre;
            font-family: "acumin-pro", Arial;
            font-weight: 300;
		}
	}
	.form-create-account {
		margin:0 auto;
		max-width:480px;
		min-width:480px;
		input,
		.field {
			text-align:left;
		}
	}

	.form-edit-account {
		fieldset {
			.field {
				.label {
					@media screen and (min-width:992px){
						width: 100%!important;
						text-align: left;
					}
				}
				.control {
					@media screen and (min-width:992px){
						width: 100%!important;
					}
				}
			}
		}
	}

	.password.forget, .password.reset {
		margin:0 30px;
		@media screen and (min-width:769px){
			margin:0 auto;
		}
		.back {
			display:none;
		}
	}
	&.customer-account-create .form-create-account {
		@media screen and (min-width:769px){
			min-width:auto !important;
			max-width:992px;
			width:100% !important;
		}
		fieldset {
			@media screen and (min-width:769px){
				width:50%;
				float:left;
			}

			fieldset {
				@media screen and (min-width:769px){
					width: 100%;
				}

				.field {
					.label {
						@media screen and (min-width:769px){
							text-align: left;
							width: 100%;
							float: left;
						}
					}
					.control {
						@media screen and (min-width:769px){
							width: 100%;
						}
					}
				}
			}

		}
		.actions-toolbar {
			margin-left: 50%;
			clear:both;

			.primary {
				transform: translateX(-25%);
			}

			.back {
				display:none;
			}
		}

	}
}
