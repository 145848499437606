.dc {
	&.faq-category-view {
		.page-title-wrapper,
		.ask-question-form,
		.amfaq-question-footer-wrapper {
			display:none;
		}
		.amfaq_questions .am-item .am-title{
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			position: relative;
			margin: 0;
			padding: 16px 0;
			.am-minus:before {
				content:'';
				@include arrow;
				width: 9px;
    			height: 9px;
				opacity:1;
				border:1px solid $black;
				border-left:0;
				border-top:0;
				transform:rotate(45deg);
				    top: 5px;


			}
			.am-plus:before {
				content:'';
				@include arrow;
				width:9px;
				height:9px;
				opacity:1;
				border:1px solid $black;
				border-left:0;
				border-top:0;
				transform:rotate(225deg);
				    top: 5px;
    position: relative;
			}
		}
		.amfaq_questions {
			max-width:460px;
			border-top: 1px solid $light-grey;
		}

		.amfaq_questions .am-content {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
				p {
					@include DesktopBody-Copy---MediumRomanBlackLeft;
					a {
						@include a;
					}
				}
			}
			&.page-layout-2columns-left .column.main,
			.sidebar.sidebar-additional {
				width:100%;
				@media screen and (min-width:769px){
					width:50%;
				}
			}
			.amfaq_categories {
				border:0;

				.item {
					&.title {
						display:none;
					}
					opacity: 0.4;
					text-transform:uppercase;
					background: transparent;
					@include DesktopH6BlackLeft;
					&.current {
					opacity: 1;
					}
				}
			}
		}


	.amfaq-product-tab {
		.block-title  {
			display:none;
		}
		.block-title strong {
			font-size:inherit;
		}
		.amfaq_questions{
			margin-bottom:24px;
		}
		.amfaq_questions .am-item {
			border-bottom:0;
			.am-links {
				float:none;
				a {
					display:block;
					text-align:left;
					@include a;
					fon-weight:normal;
					text-decoration:underline;
				}
			}
		}


	}
}