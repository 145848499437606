.fc-fc-two-column-content {
    margin: 2em 0;

    img {
        width: 100%;

        @media screen and (max-width:768px){
            margin-bottom: 2em;
        }
    }

}