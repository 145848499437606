.dc {
	div[class*="fc-carousel"]{
		@media screen and (min-width:510px) and (max-width:768px){
			width: calc(100% + 30px);
			overflow: hidden;
			position: relative;
			left: -15px;
			padding: 0;
		}
	}
	.side-wrapper {
		position:relative;
		margin:0 -15px 40px;

        @media screen and (min-width:512px){
            margin: 0;
        }

		@media screen and (min-width:768px){
			margin:0 0 20px;
		}
		@media screen and (min-width:769px){
			margin:0 -30px 80px;
		}
		@media screen and (min-width:992px){
			margin:0;
			&.bookmarks {
				margin-bottom:80px;
			}
		}
		@media screen and (min-width:1280px){
			margin-top:0;
			margin-left: calc(((1280px - 100vw) / 2 ) - -7px);
			margin-right: calc(((1280px - 100vw) / 2 ) - -8px);
			margin-bottom:80px;
		}
		> span {
			display:none;
		}

		@media screen and (min-width:768px){
			> span {
				display:block;
				@include DesktopBreadcrumbBlackLeft;
				position: absolute;
                margin-top: 20px;
                margin-left: 20px;

				width: 100%;
                max-width: 1280px;
                left: 50%;
                transform: translateX(-50%);

				a {
					@include DesktopBreadcrumbBlackLeft;
					color:#646464;
					&::after {
						content: '';
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='9' viewBox='0 0 4 9'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23000' d='M8 0L4 4 0 0' transform='rotate(-90 4.5 4.5)'/%3E%3C/svg%3E%0A");
						background-repeat: no-repeat;
						background-size: 4px 9px;
						background-position: center right;
						width: 14px;
						height: 14px;
						display: inline-block;
						position: absolute;
					    background-color: #ffffff;
					    top: 1px;
					}
				}
			}
		}
		@media screen and (max-width:768px){
			> span {
				margin-left: 30px;
			}
		}
		&.single {
			button.disabled {
				display:none;
			}
		}

        #hero_carousel_counter {
            position: absolute;
            bottom: 7em;
            color: #fff;
            font-size: 40px;
            display: flex;
            font-family: "acumin-pro", Arial;
            left: 50%;
            transform: translateX(-50%);

            font-size: 12px;
            line-height: 16px;

            @media screen and (min-width:992px){
                bottom: 5em;
                left: auto;
                right: 1em;
                font-size: 20px;
                line-height: 20px;
            }

            #slideCounterCurrent {

                padding-right: 32px;
                position: relative;

                &:after {
                    content: ' ';
                    width: 16px;
                    height: 1px;
                    background: $white;
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    opacity: .7;
                }
            }

            #slideCounterTotal {
                opacity: .7;
            }
        }

		&:before {
			content: '';
			width: 100%;
			height: 39px;
			background: url(/pub/media/theme/bottom_frame.svg?2024);
			background-repeat: no-repeat;
			background-size: 100%;
			position: absolute;
			bottom: 0.65em;
			left: 0;
			z-index: 9;

            @media screen and (min-width:768px){
                bottom: 1em;
            }
		}


		.glider-dots {
			position:absolute;
            bottom: 22px;
			width:100%;
            display: none;

			@media screen and (min-width:992px){
                right: 68px;
                width: auto;
                bottom: 40px;
                display: block;
			}

			button {
				&:only-child {
					display:none;
				}
				position:relative;
				background-color:$gold;
				height:4px;
				width:24px;
				@media screen and (min-width:992px){
					height:4px;
					width:40px;
				}
				border-radius:0;
				opacity:0.4;
				&.active {
					opacity:1;
				}

			}
		}
		button {
			position:absolute;
			top: 78%;
			transform: translateY(-50%);
			z-index: 1;
			background-repeat: no-repeat;
			background-size: 40px 70px;
			background-position: center center;
			font-size: 0px;
			width: 48px;
			height: 48px;
			background-color: $white;
            border-radius: 50%;
            border: 1px solid #A18D61;


            @media screen and (min-width:992px){
                top: 46%;
                width: 64px;
			    height: 64px;
            }

			&.next {
				right:15px;
                background: url(/pub/media/theme/arrow_right_black.svg?2024) $white;
                background-position: 50%;
                background-repeat: no-repeat;
				//background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='81' viewBox='0 0 40 81'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23D9B77B' stroke-width='3' d='M0 0L40 40 0 80' transform='translate(0 .598)'/%3E%3C/svg%3E%0A");
				//display:none;

                @media screen and (min-width:992px){
					display:block;
				}

                @media screen and (min-width:1441px){
					right:32px;
				}


			}
			&.prev {
				left:15px;
                background: url(/pub/media/theme/arrow_left_black.svg?2024) $white;
                background-position: 50%;
                background-repeat: no-repeat;
				//background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41' height='81' viewBox='0 0 41 81'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23D9B77B' stroke-width='3' d='M0 0L40 40 0 80' transform='matrix(-1 0 0 1 40.598 .598)'/%3E%3C/svg%3E%0A");
				//display:none;
                @media screen and (min-width:1441px){
					left:32px;
				}
				@media screen and (min-width:992px){
					display:block;
				}
			}

			&.disabled {
				display:none!important;
			}
		}

		.slide {
			position:relative;
			min-height: 398px;

			@media screen and (min-width:768px){
				height: 398px!important;
				overflow: hidden;
			}

			@media screen and (min-width:992px){
				height: 752px!important;
				max-height: 872px!important;
			}

			.content {
				position:absolute;
				z-index:2;
				top:0;
				left:0;
                padding: 2em 82px 52px 13px;

				@media screen and (min-width:768px){
					padding:24px 30px;
				}

				@media screen and (min-width:769px){
					padding:24px 30px;
				}

				@media screen and (min-width:992px){
					top: 50%;
					transform: translate(-50%,-50%);
					padding:0;
					padding: 0px 112px;
					max-width: 1280px;
					width:100%;
					margin: 0 auto;
					display: block;
					left: 50%;

					&.top {
						top: 24px;
						transform: translate(-50%,0);
					}
					&.bottom {
						top:auto;
						bottom:24px;
						transform: translate(-50%,0);
					}

				}
				@media screen and (min-width:1337px){
					padding:0 0 0 60px;
				}

				strong {
					font-family: "acumin-pro", Arial;
					font-size: 14px;
					font-weight: 300;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.33;
					letter-spacing: normal;
					color: $white;
					text-transform:uppercase;
					@media screen and (min-width:992px){
						@include DesktopH6GoldLeft;
					}
				}
				p {
					@include DesktopBody-Copy---SmallRomanWhiteLeft;

                    color: #fff;
                    font-family: "acumin-pro", Arial;
					font-size: 26px;
					font-weight: 300;

					@media screen and (min-width:768px) {
						max-width:50%;
					}

					@media screen and (min-width:992px) {

						@include DesktopBody-Copy---LargeRomanWhiteLeft;
						max-width:520px;
                        text-wrap: balance;

						&.sub {
							@include DesktopBody-Copy---MediumDemiWhiteLeft;
                            text-transform: uppercase;
                            font-size: 20px;
						}

					}

                    &.sub {
                        font-size: 16px;
                        text-transform: uppercase;

                        @media screen and (min-width:992px) {
                            font-size: 20px;
                        }
                    }
				}
				p:nth-child(2) {
					@include DesktopH4WhiteLeft;
                    font-family: "acumin-pro", Arial;
					font-size: 24px;
					font-weight: 300;

					@media screen and (min-width:992px){
						@include DesktopH1WhiteLeft;
					}
				}
				p:last-child > a {
					//background-color:transparent;
					@include MobileCTA-Heading---LargeWhiteCentre;
					text-align:center;
					padding:11px 24px;
					margin-top:calc(28px - 1rem);
					display:inline-block;
					background: $white;
					font-weight: bold;
                    border-radius: 100px;
                    transition: 0.3s ease-in;

                    text-transform: uppercase;
                    font-weight: 300;
                    color: $gold;

					&:hover {
						//background-color: #c89740;
						text-decoration:none;
                        //color: #fff;
					}

					@media screen and (min-width:992px){
						@include DesktopBody-Copy---SmallRomanWhiteCentre;
                        font-size: 12px;
					}
				}
			}

			img {
				width:100%;
                min-height: 398px;
                object-fit: cover;

				@media screen and (min-width:768px) {
					height:100%;
					position: absolute;
				}

				@media screen and (min-width:992px){
					position: inherit;
				}

			}

			&.overlay {
				picture {
					&:after {
						content:'';
						display:block;
						position:absolute;
						top: 0;
						left: 0;
						right: 0;
						width: 100%;
						height: 100%;
						background-color:rgba($black,0.5);
						z-index:1;
						max-height: 752px;
					}
				}
			}
		}

		&.short {
			.slide {

				@media screen and (min-width:768px){
					height: 398px!important;
					overflow: hidden;
				}

				@media screen and (min-width:992px){
					height: 480px !important;
					max-height: 480px !important;
				}
			}
		}

	}
}

.fancybox-caption-wrap {
	display:none!important;
}
