.dc {

	.column.main {
		div[class^="cart-*"]{
			border-top-color:$light-grey;
		}
		.cart-empty {
			text-align:center;
			p {
				display:none;
			}
		}
		.cart.table-wrapper .product-item-name + .item-options {
			dt {
				@include sentence-case;
			}

			dt, dd {
				@include DesktopBody-Copy---MediumRomanBlackLeft;
				font-size: 12px;
			}
		}
		.cart.table-wrapper .item-actions .actions-toolbar {
			text-align:right;
		}
		.cart-container {
			 display: flex;
			 flex-wrap: wrap;



			.form-cart {
				@media screen and (min-width:992px){
					max-width:calc(50% - 30px);
				}
				@media screen and (min-width:1024px){
					max-width:calc(70% - 30px);
				}
				@media screen and (min-width:1200px){
					max-width:754px;
				}

				width:100%;
				order:1;
				float:none;

				.fieldset.coupon{
					label {
					    @include DesktopBody-Copy---MediumDemiBlackLeft;
					}
					.control {
						width: 100%;
                        float: left;
						margin-bottom: 16px;
                        display: inline-block;
						margin-right:16px;
						@media screen and (min-width:768px){
							max-width: 248px;
							margin-bottom: auto;
						}

                        input {
                            top: 2px;
                            position: relative;
                            height: 34px!important;
                            min-height: 34px!important;
                            border-color: #A18D61!important;
                        }
					}
					.actions-toolbar {
						display: inline-block;
						max-width:105px;
						min-width: 105px;

						.primary {
							width:100%;
							.action {
								margin-top: 3px;
                                width: 128px;
								max-width: 128px;
								min-width: 105px;
								background:$white;
								border:1px solid $gold;
								@include DesktopCTA-Heading---SmallBlackCentre;
                                text-transform: uppercase;
                                border-radius: 30px;
                                padding: 0 1em;
                                width: 190px;
                                transition: background-color 0.3s ease-in;

								span {
									max-width:105px;
									min-width: 105px;
									@include DesktopCTA-Heading---SmallBlackCentre;
									padding:8px 0;
								}
							}
						}
					}
				}
				.block.discount,
				.cart.main.actions{
					@media screen and (min-width:992px){
					max-width:50%;
					}
					width:100%;
					display:inline-block;
					float:right;
				}
				.cart.main.actions{
					padding-top:28px;
				}
				.block.discount {
					// max-width:50%;
					order:2;
					margin-top:40px;
					@media screen and (min-width:992px){
						order:1;
						margin-top:auto;
					}

					float:left;
				}

				.action.continue,
				.action.update {
                    @include DesktopCTA-Heading---SmallBlackCentre;
                    background:$white;
                    border:1px solid $gold;
                    border-radius:0;
                    padding-left:24px;
                    padding-right:24px;
                    width:100%;

                    border-radius: 30px;
                    text-transform: uppercase;
                    transition: background-color 0.3s ease-in;

                    @media screen and (min-width:768px){
                        width:auto;
                    }
                    @media screen and (max-width:768px){
                        top: 0;
                    }

                    &:before{
                        content:'';
                    }

                    &:hover {
                    //background-color: #c49a50!important;
                    //color:#fff!important;
                    }

				}

				.action.apply {
					&:hover {
						//background-color: #c49a50!important;
						//color:#fff!important;
						span {
							//color:#fff!important;
						}
					 }
				}

				.cart.table-wrapper {
					border-bottom:1px solid $light-grey;
					@media screen and (max-width:991px){
						border-top:1px solid $light-grey;
					}
					th.col {
						@media screen and (max-width:991px){
							display:none;
						}
						background-color:#f4f4f4;
						padding-top:14px;
						padding-bottom:14px;
						@include DesktopH7BlackLeft;
							font-weight:normal;
							span {
								text-transform:uppercase;
								font-weight:normal;
							}
							&.subtotal {
								display:none;
							}
						}
					td.col {
						&.subtotal {
							display:none;
						}
					}

					}
					.items > .item {
						border-top:0px solid $light-grey;
						border-bottom: 0px solid $light-grey;

					}
				}

				.cart.table-wrapper .actions-toolbar > .action,
				.action-gift {
					padding:0;
					background:none;
					text-align:right;
					@include DesktopBody-Copy---SmallRomanBlackLeft;
					font-weight:normal;
					border:none;
					text-decoration:underline;
				}

			}

		.cart-summary {
			background:$white;
			border:1px solid $gold;
			width: 100%;
			margin-top:40px;

			@media screen and (min-width:992px){
				max-width:calc(50%);
				margin-left:30px;
				margin-top:0;
			}
			@media screen and (min-width:1024px){
				max-width:calc(30%);
			}
			@media screen and (min-width:1200px){
				max-width: 362px;
			}

			order:2;

			float:none;
			> * {
				@include DesktopBody-Copy---MediumRomanBlackLeft;
			}
			.cart-totals {
				border-top: 1px solid $light-grey;
				.grand .mark,
				.grand .amount{
					padding-top:8px;
				}
			}
			.totals.shipping.incl {
				span.value {
					display:none;
				}
			}
			.block .fieldset .field {
				margin-bottom:30px;
				&:last-child{
					margin-bottom:0;
				}
			}
			p {
				&.field.note {
					@include DesktopBody-Copy---MediumRomanBlackRight;
					text-align:left;
				}
			}
			label {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
			}
			.fieldset {
				margin:0 0 16px;
				dl {
					&.items.methods {
						display:none;
					}
				}
			}
			.fieldset > .field._required > .label:after {
				content:'(required)';
				color:$black;
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				font-weight:normal;
			}
			.block.active > .title:after {
				transform: rotate(180deg);
			}
			.block > .title{
				@include DesktopH7BlackLeft;
				text-transform:uppercase;
				margin-top:0;
				text-align:left;
				font-weight:normal;
				border-top: 1px solid $light-grey;
				padding: 16px 0;
				&:after {
					background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23000' d='M14 0L7 8 0 0' transform='translate(0 .412)'/%3E%3C/svg%3E%0A");
					background-position:center;
					background-repeat:no-repeat;
					content:'';
					width: 44px;
					height: 44px;
    				top: 5px;
					transform: rotate(0deg);
				}
				strong {
					@include DesktopH7BlackLeft;
					text-transform:uppercase;
					margin-top:0;
					text-align:left;
					font-weight:normal;
				}
			}

			.title {
				@include DesktopH7BlackLeft;
				text-transform:uppercase;
				margin-top:0;
				text-align:left;
				font-weight:normal;
				strong {
					text-transform:uppercase;
					margin-top:0;
					text-align:left;
					font-weight:normal;
				}
				&.summary {
					@include DesktopH6GoldCentre;
					text-transform:uppercase;
					margin:0;
					padding:16px 0;
				}

			}

			@include DesktopH7BlackLeft;

			.grand.totals {
				strong {
					@include DesktopBody-Copy---MediumDemiBlackLeft;
					font-weight:normal;
				}
			}
		}
		@media screen and (max-width:992px){
			.cart.table-wrapper {
				.item-info {
					display: flex;
					flex-wrap: wrap;
				}
				.item-actions {
					display: flex;
				    flex-wrap: wrap;
				    text-align: right;
					td {
						text-align: right;
					    white-space: normal;
					    width: calc(100vw - 40px);
					    padding: 0 0 10px 0;
					}
				}
			}
		}



		.bundle-actions .action.primary,
		.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
		.product-info-main .box-tocart .action.tocart,
		.product-options-bottom .box-tocart .action.tocart,
		.cart-container .checkout-methods-items .action.primary,
		.block-minicart .block-content > .actions > .primary .action.primary,
		.methods-shipping .actions-toolbar .action.primary, .block-authentication .action.action-register,
		.block-authentication .action.action-login,
		.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary,
		.form-address-edit .actions-toolbar .action.primary, .multicheckout .action.primary {
			height: 40px;
		    line-height: 14px;
		    padding: 0;
            border-radius: 40px;
		}

	}
	.cart-container .checkout-methods-items .action.primary {
		@media screen and (max-width:768px){
			max-width:480px;
		}
	}

	.abs-margin-for-forms-desktop,
	.fieldset .legend, .column:not(.sidebar-main) form .actions-toolbar,
	.column:not(.sidebar-additional) form .actions-toolbar,
	.login-container .fieldset:after {
		margin-left: 0;
	}

    .login-container .block .block-title strong {
        font-weight: 300;
    }

	.checkout-success {
		text-align: center;
		display: block;
		.success-messages {
			text-align:center;
			& + .actions-toolbar {
                text-align: center;
                margin: 0 auto 40px;
                width: auto;
                display: block;
                max-width: 170px;
			}
		}
	}

	&.checkout-onepage-success {
		.page-title-wrapper {
			text-align:center;

            .page-title {
                font-family: "acumin-pro", Arial!important;
                font-weight: 300!important;
            }
		}

        .action.primary span {
            min-width: 200px;
        }
	}

}

.dc > .loading-mask {
	background: rgba(255,255,255,.5);
}

.dc > .loading-mask .loader {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.dc > .loading-mask .loader img {
	width: 218px;
	height: 149px;
	max-width: 100%;
	max-height: 100%;
}

.dc > .loading-mask .loader p {
	margin-top: 80px;
	color: #d9b77b;
	font-weight: bold;
	display: block;
}

.dc .amcheckout-main-container.-modern.-layout-2columns {
    display: flex;
    justify-content: space-evenly;

	@media screen and (max-width:768px){
		flex-direction: column;
	}

	.amcheckout-column.-main {

		width: 32%;

		@media screen and (max-width:768px){
			width: 100%;
		}
	}

	.amcheckout-email {
		.amcheckout-wrapper {
			width: 100%!important;
			.validator {
				width: 100%!important;
			}
		}
	}
}
