.dc {
	#header > .ambanners,
	.nav-sections + .ambanners{
		& > div {
			//display:none;
			//@media screen and (min-width:768px){
				display:block;
				@include DesktopBody-Copy---SmallDemiBlackLeft;
				text-align:center;
				padding:7px; 
				background:$gold;
				font-size: 12px;
				padding-right: 3em;

				@media screen and (min-width:768px){
					font-size: 14px;
					padding-right: 7px;
				}

				&:before {
					content: '';
					display: block;
					background-image: url('../images/cross.svg');
					background-position: 50%;
    				background-repeat: no-repeat;
					width: 12px;
					height: 12px;
					position: absolute;
					right: 10px;
					top: 10px;
				
				}

				//background:$white;
				p {
					margin-bottom:0;
					a {
						@include a;
						text-decoration:underline;
					}
				}
				
				.block {
					display:none;
				}
			//}
		}
	}
	.page-wrapper{
		position: relative;
	}
	.header-mini-container {
		> .ambanners {
			display:none;
			@media screen and (max-width:767px){
				display:block;
				& > div {
					
					@include DesktopBody-Copy---SmallDemiBlackLeft;
					text-align:center;
					padding:7px; 
					background:$gold;
					.block {
						display:none;
					}
				}
			}
		}
	}
	
	.ambanner-5 {
		    background-color: rgba(0,0,0,0.6);
    		background-position:center center;
			background-size:cover;
			position:relative;
			@media screen and (max-width:768px){
				width: calc(100% + 40px);
				margin-left: -20px;	
			}
			& > div {
				padding: 64px 24px;
			}
			& > div:before{
				content:'';
				display:block;
				background-color: rgba(0,0,0,0.6);
				z-index:1;
				position:absolute;
				top:0; 
				left:0;
				right:0; 
				bottom:0;
				mix-blend-mode: multiply;
				
			}
			div > * {
					font-weight:normal;
					background-color:transparent;
					z-index:2;
					position:relative;
				}
			
			strong {
				 @include DesktopH7GoldCentre;
				 text-transform:uppercase;
				 display: block;
			}
			p {
				@include DesktopBody-Copy---MediumRomanWhiteCentre;
				max-width:520px;
				margin:0 auto;
				&:last-child {
					a {
						@include a;
						border:1px solid $gold;
						padding:8px 24px;
						@include DesktopBody-Copy---SmallRomanWhiteCentre;
						
					}
				}
				& + div.block {
					display:none;
				}
			}
			p > a {
				@include a;
				border:1px solid $gold;
				padding:8px 24px;
				@include DesktopBody-Copy---SmallRomanWhiteCentre;
				margin-top:24px;
				display: inline-block;
			}
			p:nth-child(2),
			h2 {
				@include MobileH3WhiteCentre;
				@media screen and (min-width:768px){
					@include DesktopH3WhiteCentre;
					font-size: 32px!important;
				}
				margin-top:24px;
				margin-bottom:24px;
			}
	}
}