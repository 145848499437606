.balance-checker h1 {
    margin-top:2em;
    font-size: 2.4em;
}

.balance-checker h1::before {
    top: 4px;
}

.balance-checker h1::after {
    top: 4px;
}

.balance-checker p {
    margin-bottom: 32px;
}
.balance-img {
    width: 100%;
    max-width: 960px;
    margin-bottom: 32px;
    margin: 0 auto 32px;
}
.balance-checker {
    text-align: center;
}

.balance-checker input {
    margin: 0 auto 64px;
}

.balance-checker input[type="radio"]{
    margin: 0;
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    visibility: visible;
    top: 8px;
    margin-right: 8px;
    margin-left: 8px;
    position: static;
    width: 1px;
    height: 1px;
    display: none;
}

.balance-checker input[type="radio"] + label {
    height: 180px;
    width: 180px;
    color: transparent;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    border:1px solid #ffffff;
    display: inline-block;
}

.balance-checker input[type="radio"]:checked + label {
    border:1px solid #d9b77b;
}

.balance-checker input[type="radio"] + label[for="hotel[945951]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/dorchester-logo.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945952]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/45-park-lane.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945953]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/coworth-park.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945954]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/plaza-athenee.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945955]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/le-meurice.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945956]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/principe-di-savoia.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945957]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/hotel-eden.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945958]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/beverly-hills-hotel.jpg');
}
.balance-checker input[type="radio"] + label[for="hotel[945959]"]{
    background-image: url('https://shop.dorchestercollection.com/media/catalog/category/hotel-bel-air.jpg');
}



.balance-checker input:first-of-type {
    margin-bottom: 16px;
}
#lookupcard {
    border:0;
    max-width: 280px;
    padding-bottom: 8px;
    min-height: 37px;

    background-color: #A18D61;
    border-radius: 42px;
    padding: 0 1em;
    border: 0;
    text-decoration:none!important;

    &:hover {
        background: #c49a50;
        color: #ffffff;
    }
}

#givexnumber {
    min-height: 48px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
}
#output-number {
    text-align: center;
    font-size: 16px;
    margin-bottom: 64px;
    margin-top: 64px;
    background: rgba(217, 183, 123, 0.1);
    padding: 32px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    strong {
        font-size: 16px;
        margin-bottom: -12px;
        display: block;
        position: relative;
        z-index: 1;
    }

    &.done {
        padding: 0;
        height: 1.375em;
        color: #000;
        font-family: Industrial736 BT;
        font-size: 3em;
        line-height: 1.83333em;
        margin-bottom: 1.66667em;
        font-style: italic;
        text-align: center;
        max-width: 400px;
        border: 1px solid #d9b77b;
        min-height: 240px;
        line-height: 231px;
        margin: 0 auto 64px;
        border-radius: 8px;
        position: relative;
        box-shadow: 0px 37px 36px -20px rgba(0,0,0,0.08);
        transition: translate 5000ms ease-in-out, box-shadow 300ms ease-in-out;
        background-image: url('https://www.dcdiamondclub.com/img/dc-logo-860.png');
        background-position: left 16px bottom 16px;
        background-repeat: no-repeat;
        background-size: 164px;

        span {
            font-size: 12px;
        }

        &:hover {
            box-shadow: 0px 45px 36px -20px rgba(0,0,0,0.16);
        }
    }
}

body.dc.cms-balance-check {
    .breadcrumbs {
        display: none;
    }

    #maincontent .columns .fc-fc-content {
        max-width: 940px;
    }
}
