* {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-weight: 400;
}
html {
  scroll-behavior: smooth;
}

.mobile-only {
	@media screen and (min-width:992px){
		display:none;
	}
}
.desktop-only {
	@media screen and (max-width:991px){
		display:none;
	}
}

#maincontent {
	@media screen and (max-width:768px){
	 overflow-y:hidden;
	}
}
#header-sticky-wrapper.sticky-wrapper {
	height:163px;
}

#amprogress {
	visibility:hidden;
}


div.mage-error[generated] {
font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $red;
}
input.mage-error, select.mage-error, textarea.mage-error {
	border-color:$red !important;
}

input.mage-error {
	& + .mage-error {
		display:block;
	}
}
input.input-text {
	& + .mage-error {
		display:none !important;
	}
}

a[href="#top"]{
	@include DesktopCTA-Heading---SmallBlackCentre;
	padding: 8px 24px;
	margin:0 auto 32px;
	display:block;
	min-width:78px;
	border:1px solid $gold;
	//max-width: 112px;
    text-decoration: none!important;

    border-radius: 100px;

    @media screen and (min-width:992px){
        margin-top: 4em;
    }

	&:hover {
		text-decoration: underline;
	}
}

@mixin a {
	color:inherit;
	text-decoration:none;
	&:hover {
		text-decoration:underline;
	}
}
@mixin arrow {
	position:relative
		&:after {
			content:'';
			position:absolute;
			width:12px;
			height:12px;
			right:4px;
			top:16px;
			border:1px solid $black;
			border-left-color:$white;
			border-top-color:$white;
			transform:rotate(45deg);
		}

}



.dc{
	.breadcrumbs {
		text-align:left;
        position: relative;
        z-index: 10;
        padding: 0;
        font-family: "acumin-pro", Arial;


        strong {
            text-transform: uppercase;
        }
	}
	.message > *:first-child:before {
		display:none;
	}
	input.input-text.mage-error + .mage-error {
	    display: block !important;
	}

	@media screen and (min-width:768px) and (max-width:992px){
		div[class*="fc-"]{
			padding-left:15px;
			padding-right:15px;
		}
		.page-main,
		.breadcrumbs {
			padding-left:15px;
			padding-right:15px;
		}
		.breadcrumbs {
			padding-bottom: 0px;
		    margin-bottom: 16px;
		}
	}

    .page-main {
        @media screen and (min-width:992px){
            padding-left: 0;
            padding-right: 0;
        }
    }

	.glider {
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
		  display: none;
		}
        @media screen and (min-width:992px){
            display: flex;
            //justify-content: center;
        }
	}



	.title,
	h1 {
        @include DesktopH4BlackCentre;
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;

        @media screen and (min-width:992px){
            @include DesktopH1BlackCentre;
            margin-top:80px;
        }
    }
	div.line {
			display: flex;
			justify-content: center; /* centers child */
			position:relative;
			margin-bottom: 30px;
			@media screen and (min-width:992px){
				margin-bottom: 40px;
			}
			&.second {
				margin-bottom: 10px;
				@media screen and (min-width:992px){
					margin-bottom: 20px;
				}
			}
			&.catalog-category-view-block {
				//margin-left: -20px;
			    //margin-right: -20px;
			    //width: calc(100% + 40px);
			}

			+ div > p {
				max-width:558px;
				margin: 0px auto 40px;
				width:100%;
				@include DesktopBody-Copy---SmallRomanBlackCentre;
				@media screen and (min-width:992px){
					@include DesktopBody-Copy---MediumRomanBlackCentre;
				}
			}
			.description {
				max-width: 664px;
				margin: 0px auto 0;
				width:100%;

                @media screen and (min-width:992px){
                    opacity: 0.75;
                }

				p {
					@include DesktopBody-Copy---MediumRomanBlackCentre;
					text-transform:none;
					font-style:normal;
				}
				p:last-of-type {
					margin-bottom:0;
				}
			}
		}


	.subtitle,
	.description,
	h2 {
		margin: 0;
		position: relative;
		display: block;
		color: $black;
        font-family: "acumin-pro", Arial;
        font-style: normal;
        font-weight: 300;
		padding:0 16px;
        font-size: 32px;

		@media screen and (max-width:410px){
			//max-width:280px;
		}

		@media screen and (min-width:992px){
			padding:0 40px;
            font-size: 36px;
		}



		&.box {
			//padding:20px 40px;
			//border:1px solid $gold;
		}


	}

	.checkout-success {
		@include DesktopBody-Copy---MediumRomanBlackCentre;
	}

	.loading-mask {
		background:transparent;
		img {
			border:0;
			max-width:16px;
			max-height:16px;
		}
	}
	.inner {
		&.box {
			margin: 0 20px;
			padding: 20px 0px;

			//border:1px solid $gold;
			//background:$white;
			z-index:2;

            @media screen and (min-width:992px){
				margin: 0;
                padding:20px 40px;
                margin: 0 30px;
			}

			.description {
				padding:0;
				@include DesktopBody-Copy---SmallRomanBlackCentre;
                text-transform:none;
                font-style:normal;
                border-bottom: 1px solid #CFB67D80;
                padding-bottom: 3em;
                margin-bottom: -1em;

                @media screen and (min-width:600px){
                    @include DesktopBody-Copy---MediumRomanBlackCentre;
                }

                @media screen and (min-width:992px){
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border: 0;
                }

				p {
					@include DesktopBody-Copy---SmallRomanBlackCentre;
					text-transform:none;
					font-style:normal;
                    color: rgba(0, 0, 0, 0.75);
                    line-height: 16px;

                    @media screen and (min-width:600px){
						@include DesktopBody-Copy---MediumRomanBlackCentre;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.75);
                        line-height: 20px;
					}

					span {
						font-style:normal;
					}
				}
				p:last-of-type {
					margin-bottom:0;
				}
			}
			.page-title {
				@include MobileH2BlackCentre;
                font-style: normal;
                font-family: "acumin-pro", Arial;
                font-weight: 300;
                font-size: 32px;
                line-height: 40px;
                margin-top:0;
				margin-bottom:24px;

                @media screen and (min-width:992px){
                    font-size: 56px;
                    line-height: 56px;
                }

                span {
                    font-weight: 300;
                }
			}
		}
	}

	.breadcrumbs {
		margin-top:16px;
        padding-left: 1em;
		@media screen and (min-width:769px){
			margin-bottom:40px;
            padding-left: 1em;
		}
        @media screen and (min-width:1280px){
            padding-left: 0;
        }
		.item {
			 @include DesktopBreadcrumbBlackLeft;
			 a {
				 @include a;
                 text-transform: uppercase;
			 }
		}
		.item:not(:last-child) {
			@include DesktopBreadcrumbGreyLeft;

            color: #000;
            opacity: .5;

			a {
				@include a;
				&:hover {
					@include a;
				}
			}
		}
	}

	table > thead > tr > th,
	table > tbody > tr > th,
	table > tfoot > tr > th,
	table > thead > tr > td,
	table > tbody > tr > td,
	table > tfoot > tr > td {
		padding:8px 16px;
	}

}

.dc .modal-popup .modal-title {
	@media screen and (min-width:992px){
		margin-top: 0;
	}
}

.glider,.glider-contain{margin:0 auto;position:relative}.glider,.glider-track{transform:translateZ(0)}.glider-dot,.glider-next,.glider-prev{border:0;padding:0;user-select:none;outline:0}.glider-contain{width:100%}.glider{overflow-y:hidden;-webkit-overflow-scrolling:touch;-ms-overflow-style:none}.glider-track{width:100%;margin:0;padding:0;display:flex;z-index:1}.glider.draggable{user-select:none;cursor:-webkit-grab;cursor:grab}.glider.draggable .glider-slide img{user-select:none;pointer-events:none}.glider.drag{cursor:-webkit-grabbing;cursor:grabbing}.glider-slide{user-select:none;justify-content:center;align-content:center;width:100%;min-width:150px}.glider-slide img{max-width:100%}.glider::-webkit-scrollbar{opacity:0;height:0}.glider-next,.glider-prev{position:absolute;background:0 0;z-index:2;font-size:40px;text-decoration:none;left:-23px;top:30%;cursor:pointer;color:#666;opacity:1;line-height:1;transition:opacity .5s cubic-bezier(.17,.67,.83,.67),color .5s cubic-bezier(.17,.67,.83,.67)}.glider-next:focus,.glider-next:hover,.glider-prev:focus,.glider-prev:hover{color:#ccc}.glider-next{right:-23px;left:auto}.glider-next.disabled,.glider-prev.disabled{opacity:.25;color:#666;cursor:default}.glider-hide{opacity:0}.glider-dots{user-select:none;display:flex;flex-wrap:wrap;justify-content:center;margin:0 auto;padding:0}.glider-dot{display:block;cursor:pointer;color:#ccc;border-radius:999px;background:#ccc;width:12px;height:12px;margin:7px}.glider-dot:focus,.glider-dot:hover{background:#ddd}.glider-dot.active{background:#a89cc8}@media(max-width:36em){.glider::-webkit-scrollbar{opacity:1;-webkit-appearance:none;width:7px;height:3px}.glider::-webkit-scrollbar-thumb{opacity:1;border-radius:99px;background-color:rgba(156,156,156,.25);-webkit-box-shadow:0 0 1px rgba(255,255,255,.25);box-shadow:0 0 1px rgba(255,255,255,.25)}}

.mobile-show {
	display:none;
	@media screen and (max-width:768px){
		display:block!important;
	}
}
.mobile-hide {
	display:none;
	@media screen and (min-width:769px){
		display:block!important;
	}
}


body.shipping-tracking-popup {

	.nav-sections {
		display: none;
	}

	#header {
		background: #000;
	}

	.header-mini-container {
		position: static;
		max-width: 0;
		min-height: 0;
		width: 100%;
		height: 52px;
		background: #000;


		.category-navigation, .switcher-store {
			display: none;
		}

		.gift-experience {
			top :0;
		}
	}

	table > tbody > tr > th {
		padding-left: 0;
	}

	a {
	    color: #000;
    	text-decoration: underline;
	}
}

#category-navigation {
	display: none;
}

@media only screen and (max-width: 992px) {
    body.home #category-navigation { display: none!important; }

	#category-navigation { display: block; }

    body.the_dorchester #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.the_dorchester .header-mini-container #switcher-store-header { display: none!important; }

	body.park_lane #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.park_lane .header-mini-container #switcher-store-header { display: none!important; }

	body.coworth_park #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.coworth_park .header-mini-container #switcher-store-header { display: none!important; }

	body.plaza_athenee #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.plaza_athenee .header-mini-container #switcher-store-header { display: none!important; }

	body.park_lane #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.park_lane .header-mini-container #switcher-store-header { display: none!important; }

	body.le_meurice #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.le_meurice .header-mini-container #switcher-store-header { display: none!important; }

	body.hotel_principe_di_savoia #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.hotel_principe_di_savoia .header-mini-container #switcher-store-header { display: none!important; }

	body.hotel_eden #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.hotel_eden .header-mini-container #switcher-store-header { display: none!important; }

	body.the_beverly_hills_hotel #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.the_beverly_hills_hotel .header-mini-container #switcher-store-header { display: none!important; }

	body.bel_air #category-navigation .switcher-option:last-child { padding-bottom: 8px; }
    body.bel_air .header-mini-container #switcher-store-header { display: none!important; }

}