.dc {
	&.cms-page-view #maincontent .columns {
		.data.item.content {
			max-width:320px;
			margin-left:auto;
			margin-right:auto;
			width:100%;
			@media screen and (min-width:769px){
			width:50%;
			float:left;
			}

			strong {
				font-weight:normal;
			}
		}
		.details-box > div {
		  display: none;
		}
		.contact-frame,
		.details-box {
			width:50%;
			float:left;

			.address-title {
				margin-top: 2em;
			}
		}
		.contact-frame .frame {
			margin-top:40px;
		}
		.details > div, .contact-frame > div {
		  display: none;
		}
		p br + a {
			margin-bottom:32px;
		}
		.wpcf7
		{
			max-width:320px;
			margin-left:auto;
			margin-right:auto;
			width:100%;
			margin-top:80px;
			@media screen and (min-width:769px){

			float:right;
			margin-top:0;
			}
			p {
				text-align:left;
				margin-bottom:24px;
			}
			fieldset .field{
				margin: 0 0 16px;
				&.required {
					label {
						&:after {
							content:'(required)';
						}
					}
				}
			}
			input[type='checkbox'] + label:before {
			    width: 24px;
			    height: 24px;
			    content: '';
			    display: inline-block;
			    background-repeat: no-repeat;
			    background-position: center center;
			    position: relative;
			    top: 6px;
			    margin-right: 8px;
			    border: 1px solid #dddddd;
			}
			input[type='checkbox'] {
			    appearance: none;
			    width: 0;
			}
			.fieldset > .field.choice:before {
				display:none;
			}
			input[type='checkbox']:checked + label {
				 // display:inline-block;
			}
			input[type='checkbox']:checked + label:before {
			    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23000000'/%3E%3Cpath d='M5 10l4 4 6-7' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
			    background-color: #000000 !important;
			}
			input[type="submit"] {
					appearance:none;
					color:$white;
					background:$gold;
					border-radius:0;
					padding:11px 24px;
					@include MobileCTA-Heading---LargeWhiteCentre;
					border:0;
					margin-top:24px;
					width: 100%;
                    border-radius: 40px;
                    text-transform: uppercase;
					&:hover {
						background:darken($gold,10%);
					}
				}
			label {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				width: 100%;
			    text-align: left;
				margin-bottom:4px;
				&:after {
					@include DesktopBody-Copy---MediumDemiBlackLeft;
					margin-left:4px;
				}
				& + .control {
					width:100%;
				}

			}

			.subtitle {
				margin: 0 .75em;
                padding: 0;
			}

		}
		.fc-fc-content,
		.fc-content {
			max-width:754px; width:100%;
			margin-left:auto;
			margin-right:auto;
			padding:0 16px;
            margin-top: 3em;

			div.wpcf7-mail-sent-ng, div.wpcf7-aborted {
				border:0;
				color:$red;
			}
			div.wpcf7-response-output {
				margin: 1em 0 2em;
				padding: 1em;
			}
			input[name*="tel"] {
				padding-left:84px !important;
			}
		}
			a {
						@include a;
						text-decoration:underline;

					}

			* {
				font-weight:normal;

			}
			h2,h3 {
				@include MobileH4BlackLeft;
				@media screen and (min-width:769px){
					@include DesktopH4BlackLeft;
				}

                font-family: "acumin-pro", Arial!important;
				padding-left:0;
				padding-right:0;

				margin-top:24px;
				// @include sentence-case;
				text-transform:none;
				margin-bottom:16px;
				strong {
					// @include sentence-case();
				}
				&:first-child {
					margin-top:0;
				}
			}

			p {
				@include DesktopBody-Copy---MediumRomanBlackLeft;

					a {
						@include a;
						text-decoration:underline;

					}
			}
			p {
				strong {
						font-weight:700;
					}
			}
			li {
				@include DesktopBody-Copy---MediumRomanBlackLeft;
					padding: 0 0 16px 20px;
					& > li > strong {
						font-weight:700;
					}

			}

			table {
				margin-bottom:40px;
				tr {
					td {
						border:1px solid #f4f4f4;
						padding:10px;
						@include DesktopCTA-Heading---SmallBlackLeft;
						@media screen and (min-width:769px){
								@include DesktopBody-Copy---SmallRomanBlackLeft;
							}
						p {
							@include DesktopCTA-Heading---SmallBlackLeft;
							@media screen and (min-width:769px){
								@include DesktopBody-Copy---SmallRomanBlackLeft;
							}
							&:empty {
								display:none;
							}
						}
						ul {
							padding:0; margin:0;
							li {
								padding:0;
								@include DesktopCTA-Heading---SmallBlackLeft;
								list-style:none;
								ul li {
									list-style: disc;
									padding: 8px 0 8px 0;
									margin-left: 16px;
									margin-bottom: 0;
								}
							}
						}
						p {
							li {
								list-style:disc;
							}
						}
					}
				}
				tr:first-child {
					th {
					    background-color: #f4f4f4;

                        font-family: "acumin-pro", Arial;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: $black;
                        border:1px solid #f4f4f4;
                        @media screen and (min-width:769px){
                            @include DesktopBody-Copy---SmallDemiBlackLeft;
				 		}

                        p {
                            font-family: "acumin-pro", Arial;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            color: $black;
                            border:1px solid #f4f4f4;
                            @media screen and (min-width:769px){
                                @include DesktopBody-Copy---SmallDemiBlackLeft;
                            }
						}

					  ul {
						  padding:0; margin:0;
						  li {
							  padding:0; margin:0;
							  list-style:none;
							  font-family: "acumin-pro", Arial;
							  font-size: 12px;
							  font-weight: normal;
							  font-stretch: normal;
							  font-style: normal;
							  line-height: 1.5;
							  letter-spacing: normal;
							  color: $black;
						  }
					  }
					}
				}
			}
		}

	}
