.dc {
	.product-info-main {
		.ambanners {
			display:block; 
			> div {
				padding:16px; 
				border:1px solid $gold;
				margin-bottom:40px;
				p {
					@include DesktopBody-Copy---SmallDemiBlackLeft;
					margin-bottom:0;
					&:last-child {
						margin-bottom:0;
					}
					&:only-child {
						margin-bottom:0;
					}
				}
			
				.block {
					display:none;
				}
				
				.products {
					ol:empty {
						display:none;
					}
				}
			}
		}
	}
}