.dc {
	.media {
		position:relative;
	}

	.char-counter {
		display: block;
        font-family: "acumin-pro", Arial;
	}

	.amcard-flex-container {
		display: block;
	}

	#pickup {
		//width: 190px;
		//border: 2px solid #000;
	}

	#gift_card_accordion {
		margin-bottom: -42px;

		@media screen and (min-width:992px){
			margin-bottom: -62px;
		}

		.data.item.title {
			margin: 0;
		}

		.data.item.content {
			display:none;
			margin-top: 0;
		}

		#gift_card_accordion_tab {
			&.active {
				& + .data.item.content {
					display: block!important;
				}
			}
		}
	}

	&.catalog-product-view {

        #maincontent {
            @media screen and (min-width:992px) {
                padding: 0;
            }
        }
		.product {
			.dc-mobile-gallery {
				display:block;
				@media screen and (min-width:992px){
					display:none;
				}
			}
			.gallery-placeholder {
				display:none;
				@media screen and (min-width:992px){
					display:block;
				}
			}
			.side-wrapper {
				width:100%;
				margin: 0 0 auto;

				@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
					margin-bottom: 2em;
				}

				>* {
					-ms-overflow-style: none;
					&::-webkit-scrollbar{
						display: none;
					}
				}
				.amasty-label-container{
					position: absolute;
				    top: 0;
				    left: 0;
				    display: inline-block;
				    width: auto !important;
					height:auto !important;
				}
				.slide {
					img {
						margin-bottom:80px;
					}
					picture:after{
						z-index:-1;
						opacity:0;
					}
				}




				.glider-dots{
					bottom:32px;
					button.glider-dot {
						left:auto;
						transform:auto;
					}

				}
				button.next,
				button.prev {
					display:block;
					left:auto;
					transform:auto;
					transform: translateY(-50%);
					top:calc(50% - 40px);
					background-size:32px 32px;
				}
				button.prev
				{
					left:15px;
				}

			}
		}



		.columns {
			display:flex;
			.column.main {
				display:flex;
				flex-wrap: wrap;
				.media {
					width: 100%;
					order:1;
					float: none;
					width: calc(100% + 32px);
					left: -16px;
				    order: 1;
				    float: none;

					@media screen and (min-width:992px){
						max-width:450px;
						width: calc(100%);
						left: auto;
                        padding-left: 1em;
					}
					@media screen and (min-width:1025px){
						//max-width:558px;
                        max-width: calc(50% - 2em);
					}

                    @media screen and (min-width:1280px){
                        padding-left: 0;
                    }

					#amasty-gallery-container {
						display:block;
						@media screen and (min-width:768px){
							display:block;
							padding-top: 1em;
						}
						.slick-track {
							margin-left:0;
							text-align:left;
						}
					}
				}
			}
		}

		.breadcrumbs {
			display:none;

			@media screen and (min-width:768px){
				display:block;
                padding-left: 1em;
			}
		}
		&.page-layout-1column .product-info-main {
			//max-width:460px;
            max-width: 570px;
			width:100%;
			order:2;
			float:none;

            @media screen and (min-width:992px){
                max-width: 500px;
            }

            @media screen and (min-width:1280px){
                max-width: 570px;
				margin-left: 28px;
			}
		}
	}
	.dc-recommended-products,
	.dc-recently-viewed,
	.additional {
		order:3;

		width: 100%;
		@media screen and (min-width:768px){
			flex-basis:100%;
            margin-bottom:80px;

		}
	}

    .dc-recently-viewed {
        .subtitle {
            font-size: 32px;
            line-height: 32px;

            @media screen and (min-width:992px){
                font-size: 56px;
                line-height: 56px;
            }
        }

        .glider {
            @media screen and (min-width:992px){
                justify-content: center;
            }
        }
    }
	.dc-instagram-products.additional{
		margin-bottom:0;
	}
	.dc-contact {
		margin-bottom:80px;
		@media screen and (max-width:510px){
			margin-top:24px;
		}
	}

	.product{
		&.info{
			&.detailed {
				margin-top:40px;

                @media screen and (min-width:768px){
                    max-width: 432px;
                }
			}
		}
		.loader {
			img {
				width:32px;
			}
		}
	}
	.product.data.items {
		border-bottom:1px solid $light-grey;
	}

	.product.media {
		img {
			margin-right:16px;
            border: 0!important;
		}
		.siema {
			@media screen and (min-width:510px) and (max-width:768px){
				.glider-slide {
					min-width: calc(100vw + 15px) !important;
				}
				img {
					margin-right:0;
				}
			}

		}
		.slick-slider {
			padding:0;
		}
		.slick-slide {
			&.slick-active.active {
				border:1px solid $light-grey;
			}

			border:1px solid $white;
			//min-width: calc(25% - 16px);
			margin-right: 0.5em;
			width: 120px !important;
			img {
				min-width:100%;
				border:2px solid $white;
				margin-bottom:0;
			}
			&:last-child {
				margin-right:0;
				img {
					// min-width: calc(100% - 16px);
					margin-right:0;
				}
			}
		}
		#amasty-gallery-images:not(.slick-vertical) .slick-list {
			margin:0;

		}
	}

	.amasty-label-text{
		@include DesktopH8WhiteLeft;
		text-transform:uppercase;
		color:$white;
		background:$black;
		margin:1px;
		padding: 8px 16px !important;
        width: auto !important;
    }

	.product-options-bottom .box-tocart .actions {
		padding-top:0;
	}

	.product-info-main{

        .ambanner-7 {
            opacity: .75;

            p {
                font-style: normal!important;
                font-weight: 300;
            }
        }


		.box-tocart .actions {
			padding-top:0;
			text-align:left;
			.tocart {
				max-width: 186px;
			}
			@media screen and (min-width:992px){
				text-align:left;
				.tocart {
                    max-width: 240px;
				}
			}
		}

		.promo_text_product {
			border: 1px solid #d9b77b;
			padding: 16px;
			color: #000;
			font-family: "acumin-pro", Arial;
			font-size: 16px;
			font-weight: 500;
			line-height: 22px;
			margin-bottom: 1em;
			margin-top: 1em;
		}

		.page-title-wrapper {
			h1,
			h1.page-title {

                font-family: "acumin-pro", Arial;
                font-weight: 300;
                font-size: 32px;
                line-height: 40px;

				margin-top:0;
				margin-bottom:0;
				text-align:left;

                @media screen and (min-width:992px) {
                    font-size: 56px;
                    line-height: 64px;
                }

                span {
                    font-weight: 300;
                }

			}
		}
		.product.attribute.overview {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			margin-top: 20px;
            font-family: "acumin-pro", Arial;
            font-size: 16px;
            font-weight: 300;
            opacity: .75;
		}

        .product.attribute.overview > div.value{
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			margin-top: 20px;
            font-family: "acumin-pro", Arial;
            font-size: 16px;
            font-weight: 300;
		}


        .product-info-price {
            opacity: .75;
        }
		.price-box {
			margin-bottom:27px;
			.price-wrapper,
			.price-wrapper .price,
			.price-wrapper .value,
			.price-wrapper span{
				@include DesktopBody-Copy---MediumRomanBlackLeft;
                font-size: 16px;
				font-weight: 300;
			}
			.price-label {
				@include DesktopBody-Copy---MediumRomanBlackLeft;
                font-size: 16px;
				font-weight: 300;
			}
			label,
			.label {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
                font-size: 16px;
				font-weight: 300;
				&:after {
					content: '(required)';
					color:$black;
					@include DesktopBody-Copy---MediumDemiBlackLeft;
					font-weight:normal;
				}
			}
		}

		.product-add-form .fieldset > .field:not(.choice) > .label {
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			font-weight:normal;

            position: relative;
            top: 14px;
            left: 1.5em;
            background: #fff;
            padding: 0 0.5em;
            font-size: 12px;
            z-index: 9;

		}

		.product-add-form .fieldset > .field.configurable:not(.choice) > .label {
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			font-weight:normal;
			text-transform:lowercase;

            position: relative;
            top: 14px;
            left: 1.5em;
            background: #fff;
            padding: 0 0.5em;
            font-size: 12px;

			&[for="qty"]{
				span {
					margin-right:8px;
				}
			}

			&:before {
				content: 'Choose ';
				text-transform: capitalize;
				color:$black;
				@include DesktopBody-Copy---MediumDemiBlackLeft;
                font-size: 12px;
				font-weight:normal;
			}

		}

		.product-add-form .fieldset > .field.required:not(.choice) > .label {
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			font-weight:normal;
            font-size: 12px;

            &[for="qty"]{
                span {
                    margin-right:8px;
                }
            }

            &:after {
                content: '(required)';
                color:$black;
                @include DesktopBody-Copy---MediumDemiBlackLeft;
                font-size: 12px;
                font-weight:normal;
                margin: 0;
            }
		}

        .field {
            margin: 0 0 10px!important;
        }

		.box-tocart,
		.product-options-bottom .box-tocart{
			display: block;
            margin-top: 0;
			.field.qty {
				display:block;
                margin-bottom: 2em!important;
                margin-top: -1em!important;
			}
		}

	}
	.dc{
		&-hotel {
			@include DesktopH7GoldLeft;
            font-weight: 300;
            font-size: 12px;
			margin-bottom:4px;
			text-transform:uppercase;
		}
		&-contact {
			strong {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				display:block;
				font-weight:normal;
				width:100%;
				margin-bottom:4px;
			}
			a {
				@include DesktopBody-Copy---SmallRomanBlackLeft;
				text-decoration:underline;
			}
		}
	}

	.product.info.detailed {
		margin-right:0;
		margin-bottom:0;
	}

    .dc-contact {
        font-size: 16px;
        font-weight: 200;
        max-width: 432px;
        opacity: .75;

        strong, a {
            font-size: 16px;
            font-weight: 200;
        }
    }

    .amtabs-accordion-view.product.data.items {
        border-bottom: 1px solid #CFB67D80;
    }

	.product.data.items {
		> .item.title {
			&.active {
				a {
					&.data.switch{
						&:after {
							transform:rotate(-180deg);
							top: 10px;
						}
					}
				}
				> &:hover ,
				> &:focus {
					.switch {
						padding-bottom:15px;
					}
				}
			}
				a {
					padding:15px 0;
					&.data.switch{
						border:0;
						border-top:1px solid #CFB67D80;
						@include DesktopH7BlackLeft;

                        font-size: 12px;
                        font-weight: 300;

						text-transform:uppercase;
						text-align:left;
                        padding: 15px 2em 0 0;
						display:flex;
						height:50px;
						position:relative;

						&:after {
							content:'';
							position:absolute;
							width: 24px;
							height: 24px;
							right:4px;
							top: 10px;
                            background: url('/pub/media/theme/arrow_down_black.svg?2024') no-repeat;
                            background-position: 50%;
                            padding: 15px 2em;

						}
					}
				}
			}
		&.amtabs-accordion-view {
			> .item.content {
				padding:0;
				border:0;

                .value {
                    font-size: 16px;
                    font-weight: 300;
                    //opacity: .75;
                }
			}
		}
		> .item.content {
            @include DesktopBody-Copy---MediumRomanBlackLeft;
            text-align:left;
            padding:0;
            border-top: 0;

            div {
                @include DesktopBody-Copy---MediumRomanBlackLeft;
                text-align:left;
                font-style: normal;
                font-size: 16px;
                font-weight: 200;

                &.am-custom-tab {

                    @media screen and (min-width:992px) {
                        padding: 0 0 1em;
                    }
                }

                &.description {
                    max-width:510px;
                    width:100%;
                    padding: 0;

                    em {
                        strong {
                            color:inherit !important;
                            text-transform:none;
                        }
                    }
                    ul, li {
                        font-size: 16px;
                        font-weight: 200;
                    }
                }

                &.value {
                    padding: 0;
                }
            }
            p {
                @include DesktopBody-Copy---MediumRomanBlackLeft;
                font-size: 12px;
                font-weight: 300;
                opacity: .75;

                @media screen and (min-width:992px) {
                    font-size: 16px;
                }

                a {
                    font-size: 12px;
                    font-weight: 300;


                    @media screen and (min-width:992px) {
                        font-size: 16px;
                    }
                }
                em {
                    font-style: normal;
                    font-weight: 300;


                    @media screen and (min-width:992px) {
                        font-size: 16px;
                    }
                }
                ul, li {
                    font-size: 12px;
                    font-weight: 300;

                    @media screen and (min-width:992px) {
                        font-size: 16px;
                    }
                }
            }
        }



		}
		.product.data.items > .item.title:not(.disabled) > .switch:focus,
		.product.data.items > .item.title:not(.disabled) > .switch:hover,
		.product.data.items > .item.title > .switch
		{
			background:$white;
		}

        .amcard-title {
            font-family: "acumin-pro", Arial;
            font-weight: normal;
            font-size: 12px;
            position: relative;
            top: 18px;
            left: 2em;
            background: #fff;
            display: inline-block;
            padding: 0 0.5em;
            margin-top: 0;
            color: #000;
        }

        .amcard-field-container, .amcard-field-block {



            .amcard-field {
                border-radius: 48px;
                border: 1px solid #CFB67D;
                min-height: 48px;
                padding: 0 1.5em!important;
                max-width: 428px!important;
                box-shadow: none!important;

                &::placeholder {
                    text-transform: uppercase;
                    font-size: 12px!important;
                    font-weight: 300!important;
                }
            }

            textarea.amcard-field {
                padding: 1em!important;
                border-radius: 12px;
            }

        }

		#amasty-gallery-images:not(.slick-initialized) {
			text-align: left;
			.amasty-gallery-thumb-link {
				margin:0;

			}
		}

		#amasty-main-container {
			max-height:568px;
			@media screen and (max-width:1024px) {
				max-height: 500px;
			}

            &:before {
                content:'';
                width: calc(100% + 12px);
                height: calc(100% + 12px);
                position: absolute;
                top: -12px;
                left: -12px;
                background: url('/pub/media/theme/product-image-mask.svg?2024');
            }
		}

		#amasty-gallery {
		position:relative;
			.loading-mask {
				position: absolute;
				padding-bottom:0 !important;
				background:transparent;
				.loader > img {
						margin: calc(50% - 24px);
						position: absolute;
						border:0;
				}
			}
			.gallery-placeholder {
				position: absolute;
			    top: 0;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    width: 100%;
			    height: 100%;
			}

		}
		.counter-label + .loading-mask {
			display:none;
		}
}


.products.wrapper ~ .toolbar .pages {
	float: none;
}

.amcard-general-container {
    display: none;
	height: 0;
    overflow: hidden;
    margin-bottom: 0;

	&.-ready {
		height: auto;
        margin-bottom: 20px;
	}
}

/** Quick hack for the gift message banner **/
.promo_text_product {
    display: none !important;
}

.beverly_hills_us .promo_text_product, .bel_air .promo_text_product {
    display: block !important;
}
