.dc {
		#customer-email-fieldset {
			.field:first-child {
				margin-bottom:24px;
			}
			label[for="password-confirmation"] + .control > .note {
				display:none;
			}
		}
		#showpassword{
			margin-bottom: 40px;
		    display: block;
		    margin-top: 0;
			&:before {
					content:'';
					width:24px;
					height:24px;
					border:1px solid $light-grey;
					margin-right:16px;
					display:inline-block;
					position: relative;
   					top: 6px;
			}
			&.active {
				&:before {
					background-color:$black;
					background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23000000'/%3E%3Cpath d='M5 10l4 4 6-7' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
					background-size:24px 24px;
					background-position:center;
				}
			}
		}


	#checkout {
		counter-reset: my-awesome-counter;
		position:relative;
		margin-top:0;
		.additional-options .field {
				margin-top: 0;
			.field.checkout-agreement.choice:before {
				float:none !important;
			}
		}

	}

	.authentication-wrapper {
		margin: 0 auto;
	    position: absolute;
	    top: -38px;
	    text-align: center;
	    width: 100vw;
		//@media screen and (max-width:767px){
			max-width:100%;
			display:none;
		//}
	    left: 50%;
		background:$white;
		.action-auth-toggle {
			background:$white !important;
			span {
				@include DesktopBody-Copy---MediumDemiBlackCentre;
				text-decoration:underline;
				&:before {
					content:'Returning customer?';
					@include DesktopBody-Copy---MediumDemiBlackCentre;
					margin-right:4px;
					text-decoration:none;


				}
			}
		}
	}
	.checkout-payment-method .credit-card-types {
		text-align:center;
	}
	.checkout-payment-method .credit-card-types .item {
		margin-bottom:0;
		max-width: 38px;
	    max-height: 32px;
		&:first-child {
			margin-right:0;
		}
	}
	.checkout-payment-method .payment-method-content .fieldset > .field {
		margin-bottom:18px;
	}
	.checkout-payment-method .payment-method-content .fieldset > .field label {
		margin-bottom:2px;
	}

	.opc-estimated-wrapper,
	#validate_address {
		display:none !important;
	}

	.amcheckout-step-container {
		a {
			@include DesktopBody-Copy---MediumDemiBlackCentre;
			@include a;
		}

        em {
            font-style: normal!important;
            font-family: "acumin-pro", Arial;
            font-weight: 300;
        }
		.additional {
			margin-bottom:0;
		}
		.fieldset > .field:not(.choice) > .label {
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			text-align:left;
			width:100%;
			padding:0;
			margin-bottom:5px;

		}
		.checkout-payment-method .step-title {
			display:inline-block;
		}

		.fieldset > .field:not(.choice) > .control {
			//width:100%;
            width: 90%;
		}
		.fieldset > .field.required > .label:after,
		.fieldset > .fields > .field.required > .label:after,
		.fieldset > .field._required > .label:after,
		.fieldset > .fields > .field._required > .label:after {
			content:'(required)';
				@include DesktopBody-Copy---MediumDemiBlackLeft;
		}
		.fieldset > .field._required:not(.choice) > .label {
			&:after {
				content:'(required)';
				@include DesktopBody-Copy---MediumDemiBlackLeft;
			}
		}
		.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-title,
		.amcheckout-main-container .amcheckout-step-container .amcheckout-title {
			@include DesktopH6GoldCentre;
			border-bottom:1px solid $gold;
            font-weight: 300;
		}
		.amcheckout-title {
			counter-increment: my-awesome-counter;
			margin-bottom: 8px;
			@include DesktopH6GoldCentre;
            font-weight: 300;
			background:$white;
			text-align:center;
			text-transform:uppercase;
			color:$gold !important;
			&:before {
				content: counter(my-awesome-counter) ". ";
				@include DesktopH6GoldCentre;
				font-weight: 300;
			}
		}
	}
	._amcheckout-selectable-container,
	.amcheckout-step-container .shipping-address-items,
	.amcheckout-step-container .amcheckout-shipping-methods
	.amcheckout-items,
	.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .amcheckout-content > .amcheckout-wrapper
	{
		background:$white;
	}
	._amcheckout-selectable-item.-selected,
	.amcheckout-step-container .amcheckout-shipping-address .billing-address-details.-selected,
	.amcheckout-step-container .amcheckout-shipping-address .shipping-address-details.-selected,
	.amcheckout-step-container .shipping-address-items .shipping-address-item.-selected,
	.amcheckout-step-container .shipping-address-items .shipping-address-item.selected-item,
	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method.-selected,
	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method.-selected,
	.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method.-selected, .amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method._active {
		border: solid 0px $light-grey;
		background:$white;

	}
	.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method-title .label {
		padding: 15px;
	}
	.checkout-payment-method .payment-method-title {
		border: solid 1px $light-grey;
  	  	background-color: $white;
	}
	.amcheckout-step-container .checkout-payment-method .payment-method-title {
		border: solid 1px $light-grey !important;
		background-color: $white;
		margin-bottom:16px;
	}.amcheckout-step-container .checkout-payment-method ._active .payment-method-title {
		margin-bottom:0;
		border-bottom: solid 0px $light-grey !important;
	}

	.amcheckout-step-container .checkout-payment-method .payment-method-content {
		border: solid 1px $light-grey;
		background-color: #f4f4f4;
		margin-bottom:16px;
		padding:16px;
	}
	.amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method._active .payment-method-content {
		padding:16px !important;
	}

	.amcheckout-step-container input[type='checkbox'] + label:hover:before, .amcheckout-step-container input[type='radio'] + label:hover:before, .amcheckout-step-container input[type='checkbox']:checked + label:before, .amcheckout-step-container input[type='radio']:checked + label:before {
		background:$white;
	}
	.amcheckout-step-container input[type='checkbox'] + label:hover:before, .amcheckout-step-container input[type='radio'] + label:hover:before, .amcheckout-step-container input[type='checkbox']:checked + label:before, .amcheckout-step-container input[type='radio']:checked + label:before
	{
		border-color:$black;
	}
	.amcheckout-step-container input[type='checkbox'] + label span:only-child,
	.amcheckout-step-container input[type='radio'] + label span:only-child {
		@include DesktopBody-Copy---MediumDemiBlackLeft;
	}

	.checkout-payment-method .payment-method-content .fieldset > .field.type .control {
		margin-left:0;
	}
	.checkout-payment-method .credit-card-types {
		margin-bottom:0;
	}
	.payment-method-braintree .hosted-control {
		width:100%;
		min-width:100%;
		height: 44px;
		border:1px solid $light-grey;
		@include DesktopBody-Copy---MediumRomanGreyLeft;
	}
	.payment-method-braintree .control .hosted-date-wrap {
		background: #ffffff;
	    border:1px solid $light-grey;
	    height: 44px;
	}
	.payment-method-braintree .hosted-control.hosted-date {
		min-width: 88px;
	    height: 42px;
	    max-width: 88px;
	    width: 88px;
		border:0px solid $light-grey;
		&:first-of-type {
			margin-right:20px;
		}
	}
	.payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
		font-size:24px;
		right: -14px;
		color:$light-grey;
	}

	._amcheckout-selectable-item:hover, .amcheckout-step-container .amcheckout-shipping-address .billing-address-details:hover, .amcheckout-step-container .amcheckout-shipping-address .shipping-address-details:hover, .amcheckout-step-container .shipping-address-items .shipping-address-item:hover, .amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method:hover, .amcheckout-step-container .checkout-payment-method .amcheckout-payment-methods .payment-method:hover {
		box-shadow:none;
	}

	.checkout-payment-method .ccard > .field.cvv > .control {
		padding-right:0;
	}

	.amcheckout-step-container .amcheckout-button:not(.braintree-googlepay-button):not(.ui-datepicker-trigger), .amcheckout-step-container button:not(.braintree-googlepay-button):not(.ui-datepicker-trigger), .amcheckout-step-container .cart.table-wrapper .actions-toolbar > .action:not(.braintree-googlepay-button):not(.ui-datepicker-trigger), .amcheckout-step-container .action-gift:not(.braintree-googlepay-button):not(.ui-datepicker-trigger) {
		background:$gold;
		color: inherit;
		font-size: 14px;
		padding-left: 15px;
	}

	.amcheckout-step-container .amcheckout-form-login .actions-toolbar, .amcheckout-step-container .form-login .actions-toolbar {
		margin-bottom:36px;
	}

	button.action.action-cancel {
		background:transparent;
		@include DesktopBody-Copy---MediumDemiBlackLeft;
		text-decoration:none;
		&:hover {
			text-decoration:underline;
			span {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				font-weight:normal;
			}
		}
	}

	.amcheckout-step-container .checkout-agreement button{
		background:transparent !important;
		@include DesktopBody-Copy---MediumRomanBlackLeft;
	}
	#checkout-step-shipping_method {
		background:$white;
		border:0px solid $light-grey;
		@media screen and (min-width:992px){
			// min-height:106px;
		}
		// max-height:106px;
		& > * {
			@include DesktopBody-Copy---MediumDemiBlackLeft;
		}
	}

	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method .col.col-method:empty {
	display:none;
	}

	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method .col.col-carrier {
		display:none!important;
	}

	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method.-selected, .dc .amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method.-selected {
		background: $white;
	}

	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method {
		&#tr_method_amstorepick_amstorepick4 {
			.col-price {
				opacity: 0;
			}
		}
	}


	.checkout-shipping-method .step-content.amcheckout-content{
		background: $white;
    	border: 0px solid #dddddd;
	}
	.amcheckout-step-container input[type='checkbox']:checked + label:before {
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23000000'/%3E%3Cpath d='M5 10l4 4 6-7' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
		background-color:$black !important;
	}

	.amcheckout-step-container input[type='checkbox'] + label[for="billing-address-same-as-shipping-shared"]:before {
		background-image:none !important;
		background-color:$white !important;
	}

	.amcheckout-step-container input[type='checkbox']:checked + label[for="billing-address-same-as-shipping-shared"]:before {
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23000000'/%3E%3Cpath d='M5 10l4 4 6-7' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
		background-color:$black !important;
	}


	.amcheckout-step-container .note {
		@include DesktopBody-Copy---MediumRomanBlackLeft;
		font-style:normal;
		margin-top:25px;
	}
	.amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container.-summary,
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container.-summary,
	.amcheckout-main-container .amcheckout-step-container.-summary{
		background:$white;
		 border:0px solid $gold;

	}
	.amcheckout-step-container .amcheckout-form-login, .amcheckout-step-container .form-login {
		border-bottom:none;
		margin-bottom:0;
	}
	.opc-block-summary .items-in-cart > .title {
		margin-top:0;
		padding: 0;
		display:none;
	}
	.opc-block-summary .step-content.amcheckout-content {
		@media screen and (min-width:992px){
			border-top:1px solid $gold;
		}
	}
	.opc-block-summary .step-content.amcheckout-content,
	.amcheckout-block.amcheckout-step-container.-summary .checkout-payment-method,
	.amcheckout-block.amcheckout-step-container.-summary .checkout-payment-method.submit{
		@media screen and (min-width:992px){
		border-left:1px solid $gold;
		border-right:1px solid $gold;
		padding:0 15px;
		}
	}
	.amcheckout-block.amcheckout-step-container.-summary .checkout-payment-method.submit {
		@media screen and (min-width:992px){
			border-bottom:1px solid $gold;
		}
	}
	.amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container .amcheckout-summary-container,
	.amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container .amcheckout-summary-container .amcheckout-title,
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-summary-container,
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .amcheckout-title,
	.amcheckout-main-container .amcheckout-step-container .amcheckout-summary-container,
	.amcheckout-main-container .amcheckout-step-container .amcheckout-summary-container .amcheckout-title {
		background:none;
	}
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .amcheckout-title,
	.amcheckout-main-container .amcheckout-step-container .amcheckout-summary-container .amcheckout-title {
		color:$gold !important;
	}
	.amcheckout-main-container.-modern.-layout-2columns .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar .amasty.checkout,
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar .amasty.checkout,
	.amcheckout-main-container .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar .amasty.checkout {
		width: calc(100%);
	    height: 44px;
	    line-height: 40px;
	    padding: 0;
		background: $gold;
        border-radius: 400px;
	}

	.table:not(.cart):not(.totals) > thead > tr > th, .table:not(.cart):not(.totals) > tbody > tr > th, .table:not(.cart):not(.totals) > tfoot > tr > th, .table:not(.cart):not(.totals) > thead > tr > td, .table:not(.cart):not(.totals) > tbody > tr > td, .table:not(.cart):not(.totals) > tfoot > tr > td {
		@include DesktopBody-Copy---MediumRomanBlackLeft;
	}
	.amcheckout-step-container .amcheckout-summary-container .table-totals .grand .mark, .amcheckout-step-container .amcheckout-summary-container .table-totals .grand .amount {
		 @include DesktopBody-Copy---MediumDemiBlackLeft;
		 font-weight:normal;
		 padding-left:0;
	}
	.amcheckout-step-container .amcheckout-summary-container .table-totals {
		margin:16px 0 0;
	}
	.amcheckout-step-container .amcheckout-summary-container .table-totals .totals {
		td,
		th {
			padding:3px 0;
		}
	}

	.amcheckout-step-container .opc-payment-additional,
	.opc-block-summary .mark .value {
		display:none !important;
	}
	.amcheckout-step-container .amcheckout-summary-container .table-totals .grand {
		border-top:1px solid $light-grey;
	}
	.amcheckout-step-container .amcheckout-additional-options {
		border:none;
		padding:0;
	}
	.amcheckout-step-container .amcheckout-additional-options .amcheckout-checkboxes, .amcheckout-step-container .amcheckout-additional-options .amcheckout-comment {
		margin:0;
	}

	.amcheckout-step-container .amcheckout-title + .amcheckout-content > .amcheckout-wrapper {
		padding:0;
	}

	.amcheckout-step-container .amcheckout-summary-container .product-item-details .product-item-name-block {
		width: 100%;
	    margin: 0;
	    display: block;
	}

    .opc-block-summary .product-item .product-item-name-block .details-qty {
        font-family: "acumin-pro"!important;
    }

	.opc-block-summary .product-item .product-item-name-block .details-qty span.value{
		margin-top: -4px;
	}
	.amcheckout-step-container .amcheckout-summary-container .details-qty {
		margin-top:11px;
	}
	.amcheckout-step-container .minicart-items .product-item-details .price-including-tax,
	.amcheckout-step-container .minicart-items .product-item-details .price-excluding-tax {
		margin-top:0;
	}
	.amcheckout-step-container .amcheckout-summary-container .details-qty .label {
		@include DesktopBody-Copy---SmallRomanBlackLeft;
		span {
			display:none;
			&.value {
				margin-top:-4px;
			}
		}
		&:after {
				content:'Quantity:';
				display:inline-block;
				@include DesktopBody-Copy---SmallRomanBlackLeft;
			}
	}
	.amcheckout-step-container .minicart-items .product-item:not(:first-child) {
		border-top:none;
	}
	.amcheckout-step-container .minicart-items .product-item {
		padding:0 0 16px;
	}
	.amcheckout-step-container .amcheckout-summary-container .product-item .product {
		padding:0;
		&.options {
			text-align:left;
			@include DesktopBody-Copy---SmallRomanBlackLeft;
			text-align:left;
			font-weight:normal;
			.subtitle {
				 @include DesktopBody-Copy---SmallDemiBlackLeft;
				 padding:8px 0 0;
				 span {
					 display:none;
					 text-align:left;
					 font-weight:normal;
					 margin-top:16px;
				 }
			}
		}
	}
	.opc-block-summary .product-item .price {
		@include DesktopBody-Copy---SmallRomanBlackLeft;
		&:before {
			content:'Item price:';
			@include DesktopBody-Copy---SmallRomanBlackLeft;
			margin-right:10px;
		}
	}
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .product-item-name strong,
	.amcheckout-main-container .amcheckout-step-container .amcheckout-summary-container .product-item-name strong {
		@include DesktopBody-Copy---SmallDemiBlackLeft;
		font-weight:normal;
	}
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .product-item-name,
	.amcheckout-main-container .amcheckout-step-container .amcheckout-summary-container .product-item-name  {
		@include DesktopBody-Copy---SmallDemiBlackLeft;
		font-weight:normal;
		hyphens: unset;
	}

	.amcheckout-step-container .amcheckout-summary-container .product-item-details .product-item-inner {
		display:block;

	}
	.amcheckout-step-container .amcheckout-summary-container .items-in-cart {
		margin-bottom:4px;
		border-bottom:1px solid $light-grey;
	}

	#checkout-shipping-method-load {
		position:relative;
		margin-bottom: 1em;
	}
	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method .col.col-method {
		//display:none;
	}
	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method .col.col-method:first-child {
		display:table-cell;
	}
	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method .col.col-comment {
		//position: absolute;
	    //top: 0;
	    //left: 0;
		//right:0;
		padding:0;
		min-height: 6.5em;
		float: left;
		width: 100%;

		.field-tooltip .field-tooltip-content {
			width: 100%;
			left: 0;
			top:0;
			display:block !important;
			border: 1px solid $light-grey;
			padding:16px;
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			font-weight: normal;
			&:before, &:after{
				display:none;
			}
		}
	}
	.checkout-payment-method .credit-card-types img {
		filter: none !important;
		-webkit-filter: none !important;
		height: 26px;
	}
	.payment-method-braintree .cvv .field-tooltip {
		right: 8px;
	    left: auto !important;
	    top: -34px;

		.field-tooltip-action {
			width: 100px;
			& > span {
				position:relative;
				@include DesktopBody-Copy---SmallRomanBlackRight;
				font-size: 11px;
			}
		}

	}

	.form-shipping-address {
		margin-top:-7px;
		.field {
			margin-bottom:24px;
			&.additional {
				margin-top: -7px;
			}
		}
	}
	.amcheckout-step-container .cart-totals .mark strong,
	.amcheckout-step-container .opc-block-summary .table-totals .mark strong,
	.amcheckout-step-container .cart-totals .grand .amount strong,
	.amcheckout-step-container .opc-block-summary .table-totals .grand .amount strong {
		padding: 16px 0;
		display:block;
	}

	.amcheckout-step-container .amcheckout-summary-container .table-totals:last-child {
		span {
			padding-bottom:16px;
			display:inline-block;
		}
	}
	.amcheckout-step-container .amcheckout-summary-container .minicart-items .product-image-container {
		border:none;
	}

	.amcheckout-step-container .amcheckout-summary-container .minicart-items .minicart-items-wrapper {
		margin-top:24px;
		padding:0;
	}

	iframe body input {
		@include DesktopBody-Copy---MediumRomanGreyLeft;
	}

	.amcheckout-step-container .amcheckout-additional-options .amcheckout-checkboxes .admin__field.admin__field-option {
		margin-bottom:0;
		label {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			&:after {
				display:none;
			}
		}
		button {
			    min-width: 300px;
		}

		&.am-gift-message {
			margin-bottom: 1em;
		}
	}

	.additional-options .field.checkout-agreement {
		&:before{
			display:none;
		}
	}
	.amcheckout-step-container {
		.checkout-agreement {
			input[type='checkbox'] + label {
				span:only-child {
					@media screen and (min-width:992px){
						top: 0;
						left: -3px;
						position: relative;
						line-height: 18px;
					}
				}
			}
		}
		input[type='checkbox'] + label {

			&:after {
				display:none;
			}

		button {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
				min-width: 85%;
			 	max-width:85%;
				@media screen and (max-width:767px){
					min-width: 230px;
					max-width: 230px;
				}
		}
		span:only-child {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			span {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			font-family: acumin-pro !important;

			}
		}

		}
		.amcheckout-additional-options .amcheckout-checkboxes .admin__field.admin__field-option label{
			@include DesktopBody-Copy---MediumRomanBlackLeft;

			.edit-link {
				padding-left: .5em;
			}
		}

		.amcheckout-additional-options .checkout-agreement .action-show {
			padding-left: 0;
		}

		.billing-address-same-as-shipping-block {
			label {
				span {
					line-height: 32px!important;
				}
			}
		}


	}

	.amcheckout-step-container .amcheckout-additional-options {
		width: calc(100% );
		margin: 0;
	}
	.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-additional-options .amcheckout-checkboxes,
	.amcheckout-main-container .amcheckout-step-container .amcheckout-additional-options .amcheckout-checkboxes {
		background-color:$white;
		padding: 0 0 20px;
		@media screen and (min-width:992px){
			border-left: 1px solid $gold;
			margin: 0;
			border-right: 1px solid $gold;
		    padding: 0 20px 20px;
		}
	}

	.amcheckout-step-container .amcheckout-shipping-methods .amcheckout-method {
		@media screen and (max-width:767px){
			padding:0;
		}
	}

	.checkout-index-index .nav-sections, .checkout-index-index .nav-toggle {
		display:block;
	}
	.block-minicart .block-content > .actions > .primary .action.primary {
		padding:14px 0;
	}

	.amcheckout-step-container input[type='checkbox'] + label:before {
		min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 0;
    border: 1px solid $light-grey;
	}
	.checkout-billing-address {
		.actions-toolbar {
			.primary {
				padding:16px 0;
				.action-update {
                    border-radius: 30px;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 8px 22px;
                    font-size: 12px;

				}
			}

		}
	}

	#validate_address {
		display:none;
	}


	#customer-email-fieldset{
		.field-tooltip,
		.amcheckout-email #tooltip
		{
			display:none;
		}
		.fieldset.hidden-fields {
			margin-bottom:0;
			display:none;
			&.active {
				.amcheckout-password {
					display:block !important;
				}
			}

		}
	}

	.amcheckout-step-container .amcheckout-form-login .amcheckout-password, .amcheckout-step-container .form-login .amcheckout-password {
		width:100%;
	}

		.amcheckout-step-container input[type='checkbox'] + label:hover:before,
		.amcheckout-step-container input[type='radio'] + label:hover:before,
		.amcheckout-step-container input[type='checkbox']:checked + label:before,
		.amcheckout-step-container input[type='radio']:checked + label:before {
		box-shadow:none;}

		.success-messages {
			p a {
				@include a;
			}
		}

		.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-shipping-address .shipping-address-item,
		.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .amcheckout-shipping-address .shipping-address-details {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			border: 1px solid #dddddd;
			a {
				@include DesktopBody-Copy---MediumRomanBlackLeft;
			}
			button.action.action-edit-address.amcheckout-button.-edit{
				background:transparent;

				@include DesktopBody-Copy---MediumRomanBlackLeft;
				font-size: 14px;
				margin-top:0;
				&:before {
					background:none;
					width: 0;
				    font-size: 14px;
				}
			}
		}
		.amcheckout-step-container .checkout-billing-address .actions-toolbar .action-cancel,
		.amcheckout-step-container .actions-toolbar .action-cancel {
			background:none !important;
			@include DesktopBody-Copy---MediumRomanBlackLeft;
				font-size: 14px;
				&:hover {
					font-size: 14px !important;
					span {
						font-size: 14px !important;
					}
				}
		}

		.amcheckout-main-container.-modern.-layout-3columns .amcheckout-step-container .billing-address-details {
			margin-top:16px;
		}


		/* New Braintree extension styles */

		.payment-method-braintree {

			#braintree_cc_number {
				padding-left: 68px;
				width: 278px;
				min-width: 0;
			}

			#braintree_expirationDate {
				width: 100px;
				min-width: 0;
			}

			#braintree_cc_cid {
				width: 100px;
				min-width: 0;
			}

			.braintree-credit-card-selected {
				height: 40px;
			}

			.hosted-control {
				height: 22px;
			}

		}

		.checkout-payment-method .credit-card-types.braintree-credit-card-types img {
			width: auto;
		}

}

