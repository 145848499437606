.dc {

	.dc-ordering {
		max-width:480px;
		margin:0 auto 40px;
		&.onestep {
			margin:0 auto 16px;
		}
		width:100%;
		.steps {
			text-align:center;
			a {
				text-decoration:none;
				color:inherit;
				&:hover {
					text-decoration:none;
					color:inherit;
					cursor:pointer;
				}
			}
			.step {
				display:inline-block;
				margin-right: 32px;
				@media screen and (min-width:992px){
					margin-right:80px;
				}
				position:relative;
				&:last-child {
					margin-right:0;
					.circle {
						&:after {
							display:none;
						}
					}
				}

				.bubble,
				.circle {
					border-radius:50%;
					width:27px;
					height:27px;
					border:1px solid $light-grey;
					background:$white;
					color:$black;
					display:block;
					position:relative;
					margin: 0 auto;
					@include DesktopH7GreyCentre;
					//line-height:27px;
                    line-height: 23px;

					&:after {
						content:'';
						width:100px;
						@media screen and (min-width:992px){
							width: 190px;
						}
						height:1px;
						background-color:$light-grey;
						position:absolute;
						top: 11px;
   					 	z-index: -1;
					}
				}

				&.active {
					&.complete,
					&.checked {
						.circle,
						.bubble {
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='28' viewBox='0 0 27 28'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23000' stroke-width='1.5'%3E%3Cpath d='M17.683 1.122C16.103.402 14.349 0 12.5 0 5.596 0 0 5.596 0 12.5S5.596 25 12.5 25 25 19.404 25 12.5c0-1.586-.295-3.102-.834-4.498' transform='translate(1 2)'/%3E%3Cpath d='M5.601 12.227L9.744 17.692 19.601 4.692' transform='translate(1 2)'/%3E%3C/g%3E%3C/svg%3E%0A");
							background-color:$white;
							background-position:center center;
							background-repeat:no-repeat;
							border:0;
							border-radius:0;
							color: transparent;
							&:after {
								background-color:$black;
							}
						}
					}
					.bubble,
					.circle {
						background:$black;
						color:$white;
						@include DesktopH7WhiteCentre;
						line-height: 23px;
					}
					.text {
						@include DesktopH8BlackCentre;
						@media screen and (min-width:992px){
							@include DesktopH7BlackCentre;
                            font-weight: 400;
						}
					}
				}
				.text {
						display:block;
						@include DesktopH8GreyCentre;
						@media screen and (min-width:992px){
							@include DesktopH7GreyCentre;
                            font-weight: 300;
						}

						text-transform:uppercase;
						margin-bottom:8px;
					}
				&.one {

				}
				&.two {

				}
				&.three {

				}
			}
		}
	}

	.dc-customer{
		@include DesktopBody-Copy---MediumDemiBlackCentre;
		margin-bottom:16px;
		a {
			@include a;
			text-decoration:underline;
		}
	}
	.dc-message {
		@include DesktopBody-Copy---MediumDemiBlackCentre;
		max-width:618px;
		padding:16px 30px;
		border:1px solid $gold;
		width:100%;
		width:100%;
		margin:0 auto 40px;
	}
}
