.dc {
	.am-custom-tab {
		p:first-child {
			strong {
				@include DesktopH4BlackLeft;
				font-weight:normal;
				text-transform: none;
			}
		}
		p {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			strong {
				@include DesktopH7GoldLeft;
				text-transform:uppercase;
				font-weight:normal;
				
			}
			a {
				@include a;
				@include DesktopBody-Copy---SmallRomanBlackLeft;
				text-decoration:underline;
				font-size: 16px;

				strong {
					font-size: 16px;
					color: #000000;
					font-weight: normal;
					text-transform: none;
				}
			}
		}
	}

	.giftmsgdesc {
		margin-top: 1em;
		font-family: acumin-pro;
		font-size: 16px;

		@media screen and (min-width:992px){
			margin-top: 2em;
		}

	}
}