.dc {
	.hotel-grid {

		margin-bottom:40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 3em;

        @media screen and (min-width:768px){
			// margin-bottom:80px;
            margin-top: 5em;
		}

		&::after,
		&::before {
		    content: '';
            @media screen and (min-width:768px){
                flex-basis: 0;
            }
            @media screen and (min-width:992px){
                flex-basis: calc(100% / 12);
            }

		}
		&:after{
		    order: 1;
		}

		.wrap {
			// margin-left:120px;
			min-width:100px;
			margin-bottom: 0;
			flex-basis: calc(100% / 2);
			position: relative;

            line-height: 20px;
            transition: 0.3s ease-in;
            padding: 1.25em 1.25em 0;

			@media screen and (min-width:768px){
				flex-basis: calc(100% / 4);
			}
			@media screen and (min-width:992px){
				flex-basis: calc(100% / 6);
                margin-bottom:40px;
                padding: 1.25em;
			}

			&:nth-child(n+6) {
			    order: 2;
			}

            &:nth-child(3) {
                .dc-svg {
                    img {
                        @media screen and (min-width:992px){
                            top: 1em;
                            max-height: 90px;
                        }
                    }
                }
            }

            &:nth-child(7) {
                @media screen and (min-width:992px){
                    padding: 1.5em;

                    .dc-svg {
                        height: 65px;
                    }
                }
            }

			&:hover {
				box-shadow: 0px 0px 11px #0000002e;
			}

		}



		.dc-svg {
			width:71px;
			height:68px;
			display:block;
			margin:0 auto 7px;

			a {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				padding-top: 2em;
			}
			img {
				display:block;
				margin:0 auto;
				text-align:center;
                max-height: 68px;
                position: absolute;
                top: 2em;
                left: 50%;
                transform: translate(-50%);
			}
		}
		.dc-name {
			text-align:center;
			min-height:22px;
            font-family: "acumin-pro", Arial;
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 1.2em;
            margin-bottom: 0.25em;
            font-weight: 300;

            @media screen and (min-width:992px){
                font-size: 19px;
            }
		}

		.dc-location {
			@include DesktopBody-Copy---SmallRomanGreyCentre;
			min-height: 22px;
			text-align: center;
            font-size: 12px;
            color: $black;
            font-weight: 300;

            @media screen and (min-width:992px){
                font-size: 16px;
            }
		}
	}
}
