.dc {
	&.page-products {
		#narrow-by-list,
		#mobile-sticky,
		#mobile-sticky-filter {
			transition:top 500ms ease-in-out;
			transition-delay: 500ms;
		}
		&.scroll-up {
			#narrow-by-list,
			#mobile-sticky,
			#mobile-sticky-filter {
				// top:0;
			}
		}
		&.scroll-down {
			#narrow-by-list {
				top:-168px !important;
			}
			#mobile-sticky {
				top:-150px !important;
			}
			#mobile-sticky-filter{
				top:-240px !important;
			}
		}

		&.page-layout-2columns-left {
			.page-main {
				//padding:0 20px;
                padding: 0;
			}
			.am-show-button.-horizontal,
			.am-show-button.-vertical {
				display:none;
			}
			@media screen and (max-width:510px){
				.breadcrumbs{
					visibility: hidden;
				    max-height: 0;
				    margin-top: 0;
				}
			}

            .breadcrumbs {
                padding: 0 1em;

                @media screen and (min-width:1280px){
                    padding-left: 0;
                }
            }

			.column.main{
                width: 100%;
                padding: 0 2em;

				@media screen and (min-width:510px) and (max-width:991px){
					order:2
				}
				@media screen and (min-width:992px){
					.filter-current + .filter-actions {
						margin-bottom:0;
					}
					.block.filter {
					  margin-bottom: 0;
					}
				}

				.products {
					@media screen and (max-width:767px){
						margin-top:0;
                        margin-bottom: 0;
					}
				}
				.toolbar-products {
					.toolbar-amount {
						float:none;
						@include DesktopBody-Copy---MediumDemiBlackLeft;
                        font-weight: 400;
                        opacity: .75;
						&:after {
							content:'found';
							display:inline-block;
							margin-left:4px;
                            font-weight: 400;
						}
					}
					.sorter,
					.limiter {
						display:none;
					}
				}
				#am-shopby-container {
					display:none;
				}
				.filter-actions {
					a {

						color:$black;
						@include a;
						@include DesktopBody-Copy---SmallRomanBlackLeft;
						line-height:42px;
						text-decoration:underline;
					}
				}
				.catalog-topnav {
					margin-bottom:24px;
					.block-title {
						&.filter-title{
							display:none;
						}
					}
					.filter-options {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        border-bottom: 0px solid #cccccc;

                        @media screen and (min-width:768px){
                            margin-top:0;
                        }
                        &-content {
                            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
                            border:none;
                            display:none;

                        }
                        &-title {
                            cursor:pointer;
                            &.open {
                                + dd {
                                    display:block;
                                }
                            }
                        }

						.filter-wrapper{
							flex-basis:calc(100% / 5);
							position:relative;
							margin-right:30px;

							@media screen and (min-width:992px){
							    margin-bottom:24px;
							}

							&.sorter {
								padding:0;
								dd li a{
									&:hover {
										text-decoration:underline;
										color:$gold;
										span {
											color:$gold;
											& > span {
												color:inherit;
											}
										}
									}
									span.label:before {
										display:none;
									}
								}
							}
							&:last-child,
							&:nth-child(4n) {
								margin-right:0;
								@media screen and (max-width:767px) {
									display:none;
								}
							}
						}

						dt {
                            @include DesktopBody-Copy---MediumRomanBlackLeft;
                            font-weight:300;
                            position:relative;
                            margin-bottom:0;
                            border: solid 1px #CFB67D80;
                            text-transform: uppercase;
                            padding: 1em 2em;
                            font-size: 12px;
                            border-radius: 44px;

                            &.open {
                                &:after {
                                    transform: rotate(-90deg) translate(50%);
                                    top: 50%;
                                }
                            }
                            &:after {
                                content:'';
                                position:absolute;
                                right: 16px;
                                top: 50%;
                                width: 24px;
                                height: 24px;
                                border: 0;
                                background: url(/pub/media/theme/arrow_right_small_black.svg?2024) #ffffff;
                                transform:rotate(90deg) translate(-50%);
                            }
						}
						dd {
							padding:16px;
							width:100%;
							margin-top:0;
							li {
								margin-bottom:16px;
								&:last-child {
									margin-bottom:0;
								}
								input {
									display:none;
								}
								input:checked + a,
								a.active,
								a.am_shopby_link_selected {
									span.label {
										&:before {
											background:$black;
											background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7' viewBox='0 0 8 7'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' d='M0 3.391L2.665 7 8 0'/%3E%3C/svg%3E%0A");
											background-position:center center;
											background-repeat:no-repeat;
											background-size:8px 7px;
											border:1px solid $black;

										}
									}
								}

                                span.value {
                                    font-weight: 300;
                                }

								span.label {
                                    font-weight: 300;
									&:before {
										content:'';
										display:inline-block;
										width:16px;
										height:16px;
										border:1px solid $light-grey;
										margin-right:14px;
										top:2px;
										position: relative;
									}
								}
							}
							a {
								@include DesktopBody-Copy---MediumRomanBlackLeft;
								cursor:pointer;
								&:hover {
									color:$gold;
									text-decoration:underline;
									span {
										color:inherit;
									}
								}

							}
							span {
								@include DesktopBody-Copy---MediumRomanBlackLeft;

							}

							ol.-am-multiselect {
								li {
									a {
										span.label {
											padding-left: 8px;
											&:before {
												display: none;
											}
										}

										&.am_shopby_link_selected {
											span.label {
												&:before {
													background:$black;
													background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7' viewBox='0 0 8 7'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' d='M0 3.391L2.665 7 8 0'/%3E%3C/svg%3E%0A");
													background-position:center center;
													background-repeat:no-repeat;
													background-size:8px 7px;
													border:1px solid $black;
												}
											}
										}

									}
									span.amshopby-choice {
										&:before {
											background: #ffffff;
											width: 16px;
											height: 16px;
											border: 1px solid $light-grey;
											top: -2px;
											border-radius: 0;
										}
									}
								}
							}

						}

						input[type='checkbox'] + .amshopby-choice:after {
							background:$black;
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7' viewBox='0 0 8 7'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' d='M0 3.391L2.665 7 8 0'/%3E%3C/svg%3E%0A");
							background-position:center center;
							background-repeat:no-repeat;
							background-size:8px 7px;
							border:1px solid $black;
							width: 16px;
							height: 16px;
							top: -2px;
						}
					}
				}
				.amasty-{
					&catalog-topnav {
						.filter-options{
							border-top:none;

						}
					}
				}
			}
		}
		 .products-grid {
			 	@media screen and (min-width:992px){
			     // padding: 0 0 0 30px;
    			 // margin: 0 -30px;

			 	}
				 ol {
					display: flex;
				 	flex-wrap: wrap;
					justify-content: left;
			 	}
			 li.product-item {
				 width:100%;
				 margin-bottom:40px;
				 padding-left:0;
				 .product {
					padding-left:0;

					.price-box .price,
					.price-box .price-label,
					.dc-price {
						  margin-bottom:70px;
					 }


				 }
				 @media screen and (min-width:768px){
					 // width:calc((100% / 2) - 30px);
					 flex-basis:calc((100% - 30px) / 2);
					 margin-right: 30px;
					 &:nth-child(2n){
						 margin-right:0;
					 }
				 }
				 @media screen and (min-width:992px){
					 // width:calc((100% / 4) - 30px);
					 flex-basis:calc((100% - 90px) / 4);
					 margin-right:30px;
					 &:nth-child(2n){
						 margin-right:30px;
					 }
					 &:nth-child(4n){
						 margin-right:0;
						 margin-left:0;
					 }
					&:nth-child(3n + 1) {
					    margin-left: 0;
					}

				}

			}


		}

		.filter-options {
			.filter-options-title {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
				font-weight:normal;
                pointer-events:all;
                border-top: 1px solid $light-grey;
                position:relative;

                padding: 16px 1em 0 1em;

                &:after {
                    content:'';
                    position:absolute;
                    right:16px;
                    top: 16px;
                    width:12px;
                    height:12px;
                    border: 0;
                    background: url('/pub/media/theme/arrow_down_black.svg?2024') no-repeat;
                    background-position: 50%;
                    transform:rotate(0deg);
                }

			}
			&-content {
				display:none;
				.items {
					.item {
						cursor:pointer;
								&:last-child {
									margin-bottom:0;
								}
								input {
									display:none;
								}
								input:checked + a,
								a.active {
									span.label {
										&:before {
											cursor:pointer;
											background:$black;
											background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7' viewBox='0 0 8 7'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' d='M0 3.391L2.665 7 8 0'/%3E%3C/svg%3E%0A");
											background-position:center center;
											background-repeat:no-repeat;
											background-size:8px 7px;
											border:1px solid $black;

										}
									}
								}

								span.label {
									&:before {
										cursor:pointer;
										content:'';
										display:inline-block;
										width:16px;
										height:16px;
										border:1px solid $light-grey;
										margin-right:8px;
										top:2px;
									}
								}
					}
					a {
						@include DesktopBody-Copy---MediumRomanBlackLeft;
						cursor:pointer;
						&:hover {
							color:$gold;
							span {
								color:inherit;
							}
						}
					}
				}
			}
			&-title {
				cursor:pointer;
				&.open {
					&:after {
					    transform: rotate(180deg);
					}
					+ dd {
						display:block;
					}
				}
			}
		}
	}

    @media only screen and (max-width: 767px) {
        .filter-options-content .am-ranges .items.-am-multiselect .item [class*='am-filter-item'] {
            padding-left: 1em;
        }

        .filter-options input[type='checkbox'] + .amshopby-choice {
            word-wrap: break-word;
            word-break: break-word;
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            margin: 0;
            padding: 0 0 0 25px;
            min-height: 25px;
            line-height: 1.2;
            cursor: pointer;
        }

        .filter-options input[type='checkbox'] + .amshopby-choice:before {
            width: 16px;
            height: 16px;
            left: 14px;
        }

        .filter-options input[type='checkbox'] + .amshopby-choice:hover:before, .filter-options input[type='checkbox']:checked + .amshopby-choice:before {
            width: 16px;
            height: 16px;
            left: 14px;
        }

        .filter-options input[type='checkbox'] + .amshopby-choice:after {
            width: 16px;
            height: 16px;
            left: 14px;
            background: $gold url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center / 25px;
            background-size: 100%;
        }
    }

	.sidebar {
		&-main {
			@media screen and (min-width:992px){
				display:none;
			}

			.filters-text {
				@include DesktopBody-Copy---MediumDemiBlackLeft;
                padding: 0 1em;
			}
		}
		#narrow-by-list {
			.sorter {
				display:none;
				+ .filters-text.mobile-only {
					display:none;
				}
			}
		}
		.items-found {
			@include DesktopBody-Copy---MediumDemiBlackLeft;
			text-align:center;
			display:block;
			margin-bottom:32px;
            color: #000;
            opacity: .75;
		}
		.filter.block {
			display:none;
			#narrow-by-list > .amshopby-items {
				display:none;
			}
		}
		.amshopby-items {
			@include DesktopBody-Copy---MediumRomanBlackLeft;
			font-weight:400;
			padding-left:0;

			visibility:hidden;
			max-height:40px;

		}
		.filter-current .item {
			margin-bottom:32px;
		}
		.amshopby-filter-current.filter-current {
			@media screen and (max-width:768px){
				visibility:hidden;
				max-height:16px;
			}
			.filter-current-subtitle {
				display:none;
			}
			+ .filter-actions {
				@include DesktopBody-Copy---MediumRomanBlackLeft;
				    position: absolute;
				    top: 0;
				    right: 1em;
				a {
					@include DesktopBody-Copy---MediumRomanBlackLeft;
					@include a;
					&.clear {
						text-decoration:underline;
					}
				}
			}
		}

        #mobile-sticky-sticky-wrapper {
            padding: 1em;
        }

		.filter-wrapper {
			&.sorter,
			&.filter-by	 {
				position:relative;
				@include DesktopBody-Copy---MediumRomanBlackLeft;
				font-weight:400;
				box-sizing:border-box;

				dt {
					font-weight:300;
					margin-bottom:0;
                    text-transform: uppercase;
					&.open {
						&:after {
                            transform: translateY(-50%) rotate(180deg);
						}
					}
					&:after {
                        content:'';
                        position:absolute;
                        right:16px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 24px;
                        height: 24px;

                        background: url('/pub/media/theme/arrow_down_black.svg?2024') no-repeat;
                        background-position: 50%;
                        background-size: 24px;
                    }
				}


			}
			&.filter-by {
					margin-right:26px;
					width:calc(50% - 15px);
					border:1px solid #CFB67D80;
					display:inline-block;
                    border-radius: 39px;
                    padding: 8px 1em;
					margin-bottom:29px;
					cursor:pointer;

                    dt:after {
                        transform: translateY(-50%) rotate(0deg);
                    }

					&.open {
						&.apply{
							background:$gold;
							color:$white;
							dt:after {
                                //transform: translateY(-50%) rotate(180deg);
							}
						}

						dt {
							color:$gold;
						}
						dt:before{
							content:'Apply';

							display:inline-block;
							@include DesktopBody-Copy---MediumRomanBlackLeft;
							font-weight:400;
							color:$white;
						}

					}
					&.arrow{
						dt:after {
                            transform: translateY(-50%) rotate(180deg);
						}
					}

					+ .sorter {
						width:calc(50% - 15px);
						border:1px solid #CFB67D80;
						display:inline-block;
						padding: 8px 1em;
                        border-radius: 39px;

						margin-right:0;
						margin-bottom:29px;
						.filter-options-title.open {
							+ dd {
								position:absolute;
                                width: 100%;
                                background: $white;
                                left: 0;
                                top: 41px;
                                z-index:10;
                                padding:8px;
                                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

                                .items .item{
                                    margin-top:8px;
                                    margin-top:8px;
                                        a{
                                        &:hover {
                                            color:$gold;
                                            span {
                                                color:inherit;
                                            }
                                        }
                                        span.label:before {
                                            display:none;
                                        }
                                    }
                                }

							}
						}
					}
			}
		}
	}

	.catalog-topnav .is-sticky .filter-options {
		padding-top:20px;
	}
	.sidebar-main .is-sticky #mobile-sticky {
		width: 100% !important;
	    left: 0;
	    padding: 20px 20px 0;
	}
	.sidebar .is-sticky #mobile-sticky-filter{
		width: 100% !important;
    left: 0;
    padding: 0 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    max-height: 220px;
    overflow-y: scroll;

	}
	.sidebar #mobile-sticky-filter-sticky-wrapper{
		height:auto !important;
		position: relative;
	}

}

#amasty-shopby-product-list {
	.products.wrapper ~ .toolbar .pages {
		float: none;

		.item {
			strong, a {
				display: inline-block;
				padding: 0 8px;
				text-decoration: none;
				font-size: 15px;
				line-height: 32px;
				text-align: center;
				color: #000000;
				font-family: "acumin-pro", Arial;
			}
			strong {
				color: #d9b77b;
			}
			.action {
				&:before {
					margin: 0 0 0 -16px;
				}
			}
		}
	}
}
