.dc {
	header {

	}
	.navigation .level0.active > .level-top,
	.navigation .level0.has-active > .level-top {
		border:0 !important;
		color:$black !important;
	}

	.breadcrumbs .item:not(:last-child):after {
		content:'';
        background: url(/pub/media/theme/arrow_right_small_black.svg?2024) $white;
		background-repeat:no-repeat;
		background-size: 24px;
		background-position:center center;
		width: 24px;
		height: 24px;
		position: relative;
        top: -2px
	}
	.navigation .level0 {
		//@extend:0 48px 0 0;
		padding:0 36px 0 0;
		color:$black;
		&.active {
			& > level-top {
				border:0;
				color:$black;
			}
		}
		&:first-child > .level-top {
			padding-left:10px;
		}
		&:last-child > .level-top {
			padding-right:0;
		}
		& > .level-top {
			@include DesktopBody-Copy---SmallDemiBlackLeft;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 300;
            line-height: 26px;
            transition: 0.3s ease-in;
			&:hover {
				color: $gold;
				a {
                    color: $gold;
				}
			}
			padding:12px 0 12px 0;

		}
	}

	.switcher-store {
		.switcher-options {
            display:none;
            @media screen and (min-width:992px){
                // display:block;
            }
        }
		&.open {
			.switcher-label {
				&:after {
					//transform:rotate(-135deg);
					//top:0px;
				}
			}
			.switcher-options {
				display:block;
				z-index: 10;
                position: absolute;

                left: 50%;
                transform: translateX(-50%);
                min-width: 90%;
				margin-left: 0.35em;

                @media screen and (min-width:992px){
                    left: 0;
                    transform: none;
                    min-width: none;
					margin-left: 0;
                }
			}
		}
	}

	.header-mini-container {
		min-height:92px;
        background: #fff;

        border-bottom: 1px solid;
        border-color: rgba(0, 0, 0, 0.1);

		@media screen and (min-width:992px){
			position:absolute;
			top: 75px;
			box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-width: 1280px;
            width: auto;
            right: 0;
            left: 0;
            top: 0;
            height: 100px;
            //border-bottom: 0;
		}
	}
	.page-header {
		background:$white;
		margin-bottom:0;
        border: 0;
		min-height:56px;
		position:relative;

		@media screen and (min-width:992px){
			min-height:100px;
            //box-shadow: 0px 0px 10px #ececec;
            border-bottom: 1px solid;
            border-color: rgba(0, 0, 0, 0.1);
		}

		.panel.header {
			display:none;
		}

        .header.content{


            @media screen and (min-width:992px){
                padding: 10px 20px 0 0;
            }
        }


		.header,
		.panel{
			background:inherit;
			&.content,
			&.wrapper {
				background:inherit;
				color:$white;
				border-bottom:0;
				border-color:transparent;
				border-bottom-width:0;

				a {
					color:$white;
					&:hover {
						color:$gold;
					}
				}
				a:visited,
				.alink:visited {
					@include a;
				}

				p {
					color:inherit;
					> a {
						@include a;
					}
				}
				.search-autocomplete {
					color:$black;
					pointer-events:all;
				}

				input {
					background:inherit;
					border:none;
					color:inherit;
					min-height:40px;
					box-sizing:border-box;

					@media screen and (min-width:992px){
						width: 92px;
						pointer-events:all;
						transition:all 300ms ease-in-out;
						&#search {
							@include DesktopBody-Copy---SmallDemiWhiteLeft;
                            color: $black;
                            text-transform: uppercase;
                            font-size: 13px;
                            border-bottom: 1px solid transparent;

							&:placeholder{
								color:$black;
							}
						}

						+ .actions{
                            right: 9em;
                            position: absolute;
                            top: 0px;
							&:after {
								cursor: pointer;
								&:hover {
									cursor: pointer;
								}
							}

						}
						+ .actions button{
								pointer-events:all;
							}

                        &.opened {
                            width: 156px;

                            + .actions{
								right: 32px;
                                /*
								&:after {
									content:'+';
									display:inline-block;
									width:40px;
									height:40px;
									font-size: 30px;
                                    transform: rotate(45deg);
                                    top: 2px;
                                    position: relative;
                                    text-align: center;
                                    line-height: 40px;
                                    right: -50px;
									color:$black;
									background: transparent;
                                    font-weight: 100;
								}
                                */
							}
							+ .actions button{
								pointer-events:all;
							}
                        }

						&:focus {
							//width: 156px;


						}
					}
					&:focus {
						border-bottom: 1px solid $white;
						outline:none;
						box-shadow: none;
					}
				}
			}
		}

		.logo {
			margin-top:0;
			margin-bottom:0;
			left: 50%;
		    transform: translateX(-50%);
		    margin: 0 auto 0;
            top: 8px;

			@media screen and (min-width:992px){
				left: auto;
				transform: none;
			}

			img {
				max-width:179px;
				width: 100%;
				@media screen and (min-width:992px){
                    max-width: 268px;
				}
				height:auto;
			}
		}

		.nav-toggle {
			top:8px;
			right: 15px;
			left:auto;
			&:hover {
				&:before {
					color:$white;
				}
			}
		}

	}

	.nav-sections {
		margin-bottom:0;
		background-color: #fff;

        .section-item-content {
            border-bottom: 1px solid;
            border-color: rgba(0, 0, 0, 0.1);
            background: #FEFEFE;
        }

	}

	/**** SEARCH ****/

	.mobile-only{
		.block-search {
			max-width: 100%;
			margin: 10px 37px 16px 24px;
    		position: relative;
			top:auto;
			input {
				position:static;
				margin:0;
				left:auto;
				border: 1px solid $gold;
    			border-radius: 0;
				height:40px;
				line-height:40px;
				@include DesktopBody-Copy---MediumRomanBlackLeft;
                border-radius: 40px;
                padding-left: 1em;
			}
			.label {
				display:none;
			}
			.label:before {
				visibility: visible;
				filter: invert(100%);
			    -webkit-filter: invert(100%);
				background-position:center top 2px;
			}
			.control {
				max-width: 85%;
			}
			.action.search {
				display:block;
				padding: 0;
			    background: none;
			    border: none;
			    position: absolute;
			    right: 10%;
			    top: 2px;
				span {
					display: block;
					font-size:1px;
					background-repeat:no-repeat;
					background-size:20px 21px;
					background-position:center center;
					width:35px;
					height:35px;
                    background: url(/pub/media/theme/search.svg?2024) $white;
                    background-repeat: no-repeat;
                    background-position: 50%;
				}
			}
		}
	}


	.account-link {
		display:none;
        /*
		@media screen and (min-width:992px){
			display:block;
			top: 56px;
			float:right;
			padding:0 28px;
			@include DesktopBody-Copy---SmallDemiGoldLeft;
			position: relative;
            z-index: 1;
            right: 14px;
			a {
				color:$gold !important;
			}
		}
        */
	}

	.return-link {
		display:none;
		@media screen and (min-width:992px){
			display: block;
			position: absolute;
            right: 0;
            top: 1.25em;
            color: $black;
            z-index: 9;

			a {
				color: $black!important;
				border-bottom: 1px solid $gold;
				padding-bottom: 2px;
				font-weight: 400;
                text-transform: uppercase;

                font-family: "acumin-pro", Arial;
                font-size: 12px;
                font-weight: 300;
                padding-bottom: 6px;

                transition: 0.3s ease-in;

				&:hover {
					text-decoration: none;
                    color: $gold!important;
				}
			}
		}
	}

	.block-search .control {
		border-top:none;
		margin:0 -15px;
	}

	.block-search .label:before {
		content:'';
		background-repeat:no-repeat;
		background-size:35px 33px;
		background-position:center center;
		width:35px;
		height:35px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='22' viewBox='0 0 21 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23FFF' transform='rotate(-45 10.243 2.828)'%3E%3Cpath d='M5 10L5 16.213'/%3E%3Ccircle cx='5' cy='5' r='5'/%3E%3Cpath d='M5.707 1.957c-2.071 0-3.75 1.679-3.75 3.75'/%3E%3C/g%3E%3C/svg%3E%0A");
		visibility:hidden;
	}
	@media screen and (min-width:992px){

		.block-search {
			max-width: 220px;
            top: 3.8em;
            position: absolute;
            right: 36px;
			//pointer-events:none;
			z-index:11;

			.control {
				padding: 0 0 25px 10px;
				margin:0;
			}

			.action.search {
                right: -22px;
				top: 2px;
			}

		}
	}

	.block-search .action.search:before{
		content: '';
		background-repeat: no-repeat;
		background-size: 20px 21px;
		background-position: center center;
		width: 32px;
		height: 32px;
		background: url(/pub/media/theme/search.svg?2024) $white;
        background-repeat: no-repeat;
        background-position: 50%;
	}

    .block-search .action.clear{
        display:none;
        width:40px;
        height:40px;
        top: 2px;
        position: relative;
        text-align: center;
        line-height: 40px;
        right: -50px;
        font-weight: 100;
        cursor: pointer;
        z-index: 100;
        background: url(/pub/media/theme/ico_close.svg?2024) $white;
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: 50%;

        &.show {
            display: inline-block;
        }
	}

	.block-search .action.search.disabled,
	.block-search .action.search[disabled],
	fieldset[disabled] .block-search .action.search {
		opacity:1;
	}
	.block-minicart .subtitle.empty {
		color:#000000;
		@include DesktopBody-Copy---MediumRomanBlackLeft;
		text-transform:none;
		font-weight:normal;
		font-style: normal;
    	padding: 20px 0 20px;
		position: absolute;
	    top: 3px;
	    left: 0;
	    width: 100%;
	    z-index: -1;
		@media screen and (max-width:510px){
			padding: 20px 0 20px 32px;
			font-size:12px;
		}
	}

	.minicart-wrapper {
		left: 15px;
        top: 0;
		position:absolute;
		@media screen and (min-width:640px){
			top: auto;
		}

		@media screen and (min-width:992px){
			left: auto;
            right: -0.75em;
            top: 4em;
            z-index: 1;
		}
		.action.showcart .counter.qty{
			background: transparent;
            top: 2px;
            right: 24px;
            width: 0;
            position: absolute;

            @media screen and (min-width:992px){
                top: 5px;
            }
		}
		.block-minicart {
			right:auto;
			@media screen and (min-width:992px){
				right:8px;
			}
			&:before,
			&:after {
				left:auto;
				right:0;
				@media screen and (max-width:991px){
                    left:8px;
                    right:auto;
				}
			}
		}
		.action.showcart:before {
			position: relative;
			top: 2px;
			content: '';
			background-repeat: no-repeat;
			background-position: center center;
			width: 32px;
			height: 32px;
            background: url(/pub/media/theme/cart.svg?2024) $white;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 24px;

            @media screen and (min-width:991px){
                background-size: 16px;
            }
		}
		.action.showcart.gold:before {
            background: url(/pub/media/theme/cart_gold.svg?2024) $white;
            background-repeat: no-repeat;
            background-position: 50%;

            @media screen and (min-width:991px){
                background-size: 16px;
            }
		}

        .action.showcart:hover:before {
            background: url(/pub/media/theme/cart_gold.svg?2024) $white;
            background-repeat: no-repeat;
            background-position: 50%;

            @media screen and (min-width:991px){
                background-size: 16px;
            }
        }
	}

	.action.advanced {
		display:none;
	}


	.gift-experience {
		@include DesktopH4WhiteCentre;

		text-align:center;
		padding: 12px 0;
        color: #000;


        font-family: "acumin-pro", Arial;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;

		@media screen and (min-width:992px){
			position:absolute;
			@include DesktopH1WhiteCentre;
			left:50%;
			transform:translate(-50%,-50%);
			max-width:480px;
			text-align:center;
			width:100%;
			top: 50%;
			padding:0;
            font-weight: 300;
		}

	}


	/****** DROPDOWN SECTION *****/

	@media screen and (min-width:992px){
		#switcher-store {
			max-width: 267px;

			.switcher-options {
				display:none;
			}
			&:hover {
				.switcher-options {
					display:block;
				}
			}
		}
	}


	.mobile-only{

		&.sidebar-label {
			display:none;
			@include DesktopH7BlackLeft;
            font-weight: 300;
			margin-bottom:24px;
			text-transform:uppercase;
		}
		.switcher-options{
			margin: 0 5px 0 24px;
			padding-left: 0;
			color: $black;
			z-index: 1;
			display:block;
		}
		.switcher-store a{
			@include DesktopBody-Copy---SmallRomanBlackLeft;
			font-weight: 400;
			margin-left:0;
		}
		.switcher-store .location{
			margin-left:0;
		}
		.switcher-store strong.switcher-label{
			display:none;
		}
		.switcher-dropdown .switcher-option:last-child{
			padding-bottom:0;
			a {
				margin-bottom:0;
			}
		}

		.link {
			@include DesktopH7BlackLeft;
			text-transform:uppercase;
			padding:16px 0 16px 0;
			margin:0 12px 0 24px;
			border-top:1px solid $light-grey;
			a {
				color:inherit;
			}
			&:nth-child(1){
				bottom:40px;
			}
			position: absolute;
			bottom: 0;
			width: calc(100% - 40px);

			&:nth-child(1),
			&.one{
				bottom:106px;

				@media only screen and (max-width: 280px){
					bottom:128px;
				}
			}

			&:nth-child(2),
			&.two{
				bottom:53px;

				@media only screen and (max-width: 280px){
					bottom:75px;
				}
			}

		}

		.return {
			padding:16px 0 16px 0;
			margin:0 12px 0 24px;
			border-top:1px solid $light-grey;
			@include DesktopBody-Copy---SmallDemiBlackLeft;

			position: absolute;
            bottom: 0;
            width: calc(100% - 40px);

			a {
				border-bottom:1px solid $gold;
				color:inherit;
				padding-bottom:2px;
				&:hover {
					text-decoration:none;
				}
			}
		}

	}
	//@media only screen and (max-width: 767px){

	@media only screen and (max-width: 1024px){
			.nav-sections .mobile-only {
				.switcher-dropdown {
					display:block;
					padding-top:0px;
					margin-top:0;
				}
				.switcher {
					border-top:none;
					&-store {
						margin-top:24px;
						// max-height:313px;
						height: calc(100vh - 239px);
						height: calc((var(--vh, 1vh) * 100) - 294px );
						overflow-y:scroll;
						overflow-x:hidden;
						margin-right:5px;
						// margin-bottom: 24px;

						&::-webkit-scrollbar {
						  -webkit-appearance: none;
						  width: 8px;
						}

						&::-webkit-scrollbar-thumb {
						  border-radius: 0;
						  background-color: $light-grey;
						  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
						}
						&::-webkit-scrollbar-track {
							background-color:#f4f4f4;
						}

						a {
							&:hover {
								color: $gold;
								text-decoration:none;
							}
						}

					}
				}
				.switcher:last-child{
					border-bottom:none;
					padding-bottom: 0;
    				margin-bottom: 0;

				}
			}
		}


	.switcher-dropdown {
		@media screen and (min-width:992px){
			padding-top:16px;
		}
		.switcher-option:last-child{
			padding-bottom:32px;
			@media screen and (min-width:992px){
				padding-bottom:8px;
			}
		}
	}
	.switcher-options {
		padding:0 15px 0px;
		background: $white;
		color: $black;
		// transform:translateY(-100%);
		// margin-top:-100%;
		// margin-top: -502px;
		@media screen and (min-width:992px){
			margin-top: 0px;
			max-width: 267px;
			z-index:1;
			padding-left:24px;
			padding-right:24px;
			a:hover {
				color:$gold;
				text-decoration:none !important;
			}
		}

		position:relative;
		// z-index: -1;
	}




	.switcher-store {

        padding:0px;

		@media screen and (min-width:992px){
			width:100%;
			max-width: 267px;
            display: inline-block;
            top: 64px;
            position: relative;
		}

        &.open {
            .switcher-label {
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

		strong {
			@include DesktopBody-Copy---SmallDemiGoldLeft;
			&.switcher-label {

				cursor: pointer;
				@include DesktopBody-Copy---SmallDemiGoldLeft;
				display: block;
				width: 100%;
				text-align: center;
                color: $black;
                padding-bottom:16px;
                text-transform: uppercase;

                border: 1px solid #CFB67D80;
                margin: 0 auto;
                width: 90%;
                border-radius: 40px;
                line-height: 40px;
                padding-bottom: 0;
                margin-bottom: 1em;

                text-align: left;
                padding-left: 2em;
                position: relative;

				@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
					line-height: 3.8;
					padding-bottom: 0;
				}

				@media screen and (min-width:992px){
					text-align:left;
					@include DesktopBody-Copy---SmallDemiGoldLeft;
                    color: $black;
                    font-size: 12px;
					//padding-left:20px;
                    width: 100%;
                    margin: 0;
                    border:0;
                    border-radius: none;
                    padding-left: 0;
				}


				&:after {
					content:'';
                    width: 18px;
                    height: 18px;
                    display: inline-block;
                    background: url('../images/arrow_down_gold.svg');
                    background-position: 50%;
                    position: relative;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-left: 1em;

                    @media screen and (max-width:992px){
                        right: 1em;
                    }

				}
			}
		}
		.location {
			@include DesktopH7GoldLeft;
			text-transform:uppercase;
			margin-bottom: 11px;
		    margin-top: 31px;
		    margin-left: -4px;
            font-weight: 300;
			&.first {
				margin-top:0;
			}
		}
		a {

			@include a;
			@include DesktopBody-Copy---SmallRomanWhiteLeft;
			margin-bottom: 15px;
            display: block;
            margin-left: -5px;

		}
	}

	.navigation {
		background: transparent;

        @media screen and (min-width:992px){
            //height: 52px;
        }

		.nav__shadow {
            width: $nav-shadow-width;
            height: $nav-height;
            position: absolute;
            top: 0;
            // Using 0% rgba value instead of transparent because of Safari.
            background: linear-gradient(to right, rgba($gold, 0), $gold 80%);
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.1s;

            &.is-visible {
            opacity: 1;
            }
		}

		.nav__shadow--start {
            left: 0;
            transform: rotate(180deg);
		}

		.nav__shadow--end {
            right: 0;
		}


		@media screen and (min-width:769px){
			ul {
				overflow-x: auto;
				overflow-y: hidden;
				-webkit-overflow-scrolling: touch;
				white-space: nowrap;
                //height: 52px;
				-ms-overflow-style: none;
				display: flex;
				justify-content: center;

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		li {
			@include DesktopBody-Copy---SmallDemiBlackLeft;
		}
	}

	@media screen and (max-width:1024px){
		.nav-sections {
			-webkit-overflow-scrolling: touch;
			-webkit-transition: right 0.3s;
			-moz-transition: right 0.3s;
			-ms-transition: right 0.3s;
			transition: right 0.3s;
			height: 100%;
			right: -80%;
			right: calc(-1 * (100% - 54px));
			left:auto;
			overflow: hidden;
			position: fixed;
			top: 0;
			width: 80%;
			width: calc(100% - 54px);
			background: $white;
			&-item-title {
				display:none;
			}
			&-item-content {
				margin-top:0;
				padding:0;
				height: 100vh;
				height: calc((var(--vh, 1vh) * 100) - 24px);
    			position: relative;
			}
		}
	}


	//@media only screen
	//and (min-device-width : 768px)
	//and (max-device-width : 1024px)  {
	//	.nav-sections {
	//		-webkit-overflow-scrolling: touch;
	//		-webkit-transition: right 0.3s;
	//		-moz-transition: right 0.3s;
	//		-ms-transition: right 0.3s;
	//		transition: right 0.3s;
	//		height: 100%;
	//		left:auto;
	//		overflow: auto;
	//		position: fixed;
	//		top: 0;
	//		width: 80%;
	//		width: calc(50% - 54px);
	//		background: $white;
	//		&-item-title {
	//			display:none;
	//		}
	//		&-item-content {
	//			margin-top:0;
	//			padding:0;
	//			height: 100vh;
	//			height: calc((var(--vh, 1vh) * 100) - 24px);
    //			position: relative;
	//		}
	//	}
	//}

	.category-navigation {
		display:none;
	}


	&.the_dorchester, &.bel_air, &.beverly_hills_us {
		.navigation {
			ul {
				overflow: visible;
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				//padding: 0 20px;
                padding: 0;

				.level0 {
					padding: 0;

					&:first-child > .level-top {
						padding-left: 0px;
					}
				}

			}
		}
	}
}

@media only screen and (max-width: 1024px){
	.nav-open {
		.dc {
			.nav-sections {
				-webkit-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				-moz-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				-ms-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				right: 0;
				left:auto;
				z-index: 99;
                background: $white !important;
			}
			.page-wrapper {
				left:auto;
			}
			.sidebar-label {
				display:block;
				@include DesktopH7BlackLeft;
				margin-bottom:24px;
				text-transform:uppercase;
                font-weight: 300;
			}
			.mobile-only{
				+ .navigation.nav__list {
					display:none;
				}
			}
		}
	}
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
	.nav-open {
		overflow: hidden;
		height: 100vh;

		.dc {
			.nav-sections {
				-webkit-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				-moz-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				-ms-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
				right: 0;
				left:auto;
				z-index: 99;
			}
			.page-wrapper {
				left:auto;

				&:before {
					content:'';
					width: 100%;
					height: 100%;
					position: fixed;
					top: 0;
					left: 0;
				}
			}
			.sidebar-label {
				display:block;
				@include DesktopH7BlackLeft;
				margin-bottom:24px;
				text-transform:uppercase;
                font-weight: 300;
			}
			.mobile-only{
				+ .navigation.nav__list {
					display:none;
				}
			}
		}
	}

	.nav-before-open {
		.dc {
			.page-wrapper {
				-webkit-transition: right 0.3s;
				-moz-transition: right 0.3s;
				-ms-transition: right 0.3s;
				transition: right 0.3s;
				height: 100%;
				right: 0;
				left:auto;
				overflow: hidden;
				position: relative;
			}
		}
	}
}


@media screen and (max-width:992px){
.gift-experience {
		&.arrow {
			&:after {
                /*
				content:'';
				border:1px solid $white;
				border-left-color:$black;
				border-top-color:$black;
				width:10px;
				height:10px;
				display:inline-block;
				margin-left: 12px;
				position:relative;
				top:-3px;
                */
			}
			&.open {
				&:after {
					transform:rotate(-135deg);
					top:2px;
				}
			}
		}
	}
	.gift-experience.open {
		+ .category-navigation {
			display:block;
		}
	}

	.category-navigation {
		max-height:480px;
		padding:27px 19px 27px 27px;
		display:none;
		background: #fff;

		@media screen and (min-width:992px){
			display:none;
		}
/*
		+ .switcher-store {

			padding-top:10px;
			padding-bottom:0;
			background: $gold;

			strong.switcher-label {
				@include DesktopBody-Copy---SmallDemiBlackCentre;
				background:$gold;
				color:$black;
				padding:0 0 8px;
                font-size: 12px;

				&:after {
					border: 1px solid $black;
					border-top-color: $gold;
					border-left-color: $gold;
				}
			}
			&.open > .switcher-options {
				padding-top:24px;
                padding-left: 2em;

                @media screen and (min-width:992px){

                }
			}
		}
        */
		ul {
			max-height:424px;
			overflow-x:hidden;
			overflow-y:auto;
			margin:0;
			padding:0;

			li {
				list-style:none;
				list-style-type:none;
				display:block;
				text-align:left;
				width:100%;
				margin-bottom:12px;
				a {
					@include DesktopBody-Copy---MediumRomanBlackLeft;
				}
			}
			&::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 8px;
			}

			&::-webkit-scrollbar-thumb {
                border-radius: 0;
                background-color: $light-grey;
                box-shadow: 0 0 1px rgba(255, 255, 255, .5);
			}
			&::-webkit-scrollbar-track {
				background-color:#f4f4f4;
			}

		}
	}

	.close.nav-toggle {
        position: absolute;
        left: auto;
        right: -26px;
        top: 4px;
        bottom: auto;
        width: 32px;
        height: 32px;
        color: #000000;
        font-weight: 100;
        text-align: center;
        line-height: 20px;
        font-family: inherit;
        background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PCFbQ0RBVEFbCgkuc3Qwe2ZpbGw6IzAxMDEwMTt9Cl1dPjwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZmlsbD0iIzAxMDEwMSIgZD0iTTM0MC4yIDE2MGwtODQuNCA4NC4zLTg0LTgzLjktMTEuOCAxMS44IDg0IDgzLjgtODQgODMuOSAxMS44IDExLjcgODQtODMuOCA4NC40IDg0LjIgMTEuOC0xMS43LTg0LjQtODQuMyA4NC40LTg0LjJ6Ii8+PG1ldGFkYXRhPjxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6cmRmcz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC8wMS9yZGYtc2NoZW1hIyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIj48cmRmOkRlc2NyaXB0aW9uIGFib3V0PSJodHRwczovL2ljb25zY291dC5jb20vbGVnYWwjbGljZW5zZXMiIGRjOnRpdGxlPSJpb3MsY2xvc2UsZW1wdHkiIGRjOmRlc2NyaXB0aW9uPSJpb3MsY2xvc2UsZW1wdHkiIGRjOnB1Ymxpc2hlcj0iSWNvbnNjb3V0IiBkYzpkYXRlPSIyMDE3LTA5LTI0IiBkYzpmb3JtYXQ9ImltYWdlL3N2Zyt4bWwiIGRjOmxhbmd1YWdlPSJlbiI+PGRjOmNyZWF0b3I+PHJkZjpCYWc+PHJkZjpsaT5CZW5qYW1pbiBKIFNwZXJyeTwvcmRmOmxpPjwvcmRmOkJhZz48L2RjOmNyZWF0b3I+PC9yZGY6RGVzY3JpcHRpb24+PC9yZGY6UkRGPjwvbWV0YWRhdGE+PC9zdmc+);
        background-position: 50%;
        background-size: 48px;
        background-repeat: no-repeat;

		&:before,
		&:after {
			content:'';
			position:absolute;
			display:none;
		}
	}



}

//**** Navigation sticky
	.dc .page-wrapper {
		position:relative;
		#header {
			z-index:1000;
            padding: 0 1em;
            background: #fff;

            @media screen and (max-width:992px){
                padding: 0;
            }
		}
	}
	/* The sticky class is added to the navbar with JS when it reaches its scroll position */
	.sticky {
        top: 0;
        width: 100%;
	}
	#header-sticky-wrapper {
		z-index:1000;
	}
	body > .ambanners {

		top: 0;
		width: 100%;
	}
	/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */

	.header-sticky-inner {
		position:relative;
	}


@media screen and (min-width:992px){
	#search::placeholder {
		color:$black!important;
	}
}

@media screen and (min-width:992px){
	body.cms-page-view, body.cms-no-route, body.customer-account-login {
		.fc-fc-header {
			margin-top: 4em;
		}
	}
}

@media only screen and (max-width: 767px){
	body.cms-balance-check {
		.fc-fc-header {
			margin-top: 4em;
		}
	}
}


.fc-fc-header {

	h1 {
		//max-width: 280px;
        max-width: 332px;
		display: block;
		z-index: 1;
		padding: 0 16px;
		font-style: italic;
		line-height: 1.25;
		background: #fff;
		margin: 40px auto 20px auto;

        font-family: "acumin-pro", Arial;
        font-style: normal;
        font-weight: 300;

		@media screen and (min-width:992px){
			max-width: none;
            font-size: 56px;
			margin-top: 35px;
		}
	}

    h2 {

        @media screen and (min-width:992px){
			max-width: none;
            font-size: 56px;
			margin-top: 35px;
		}

    }

    h3 {
        font-family: "acumin-pro", Arial;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;

        @media screen and (min-width:992px){
            font-size: 36px;
        }
    }

	.subheading {
		text-align: center;
        max-width: 870px;
        margin: 0 auto 3em auto;

        font-family: "acumin-pro", Arial;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        line-height: 20px;
        opacity: .75;

        @media screen and (min-width:992px){
            margin: 0 auto 4em auto;
            font-size: 16px;
        }
	}
}
