$nav-height: 45px;
$nav-scrollbar-height: 45px;
$nav-shadow-width: 60px;

/******* COLOUR VARIABLES *********/

$white: #ffffff;
$light-grey: #dddddd;
$red: #cc0000;
$dark-grey: #646464;
$black: #000000;
$tan: #c49a50;
$light-green: #e9f3e7;
$light-gold: rgba(217, 183, 123, 0.4);
//$gold: #d9b77b;
$gold: #A18D61;
$off-white: #fdfbf9;



/****  FONT VARIABLES AND MIXINS *******/

@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

@font-face {
  font-family: Industrial736BT;
  src: local(Industrial736BT-Italic),
    url(../fonts/hinted-subset-Industrial736BT-Italic.woff2) format("woff2"),
    url(../fonts/hinted-subset-Industrial736BT-Italic.woff) format("woff"),
    url(../fonts/hinted-subset-Industrial736BT-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: Industrial736BT-Roman;
  src: local(Industrial736BT-Roman),
    url(../fonts/hinted-subset-Industrial736BT-Roman.woff2) format("woff2"),
    url(../fonts/hinted-subset-Industrial736BT-Roman.woff) format("woff"),
    url(../fonts/hinted-subset-Industrial736BT-Roman.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

/*

@font-face {
  font-family: "acumin-pro", Arial;
  src: local("acumin-pro", Arial-Bold),
    url(../fonts/hinted-subset-"acumin-pro", Arial-Bold.woff2) format("woff2"),
    url(../fonts/hinted-subset-"acumin-pro", Arial-Bold.woff) format("woff"),
    url(../fonts/hinted-subset-"acumin-pro", Arial-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "acumin-pro", Arial;
  src: local("acumin-pro", ArialItalic),
    url(../fonts/hinted-subset-"acumin-pro", ArialItalic.woff2) format("woff2"),
    url(../fonts/hinted-subset-"acumin-pro", ArialItalic.woff) format("woff"),
    url(../fonts/hinted-subset-"acumin-pro", ArialItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "acumin-pro", Arial;
  src: local("acumin-pro", Arial),
    url(../fonts/hinted-subset-"acumin-pro", Arial.woff2) format("woff2"),
    url(../fonts/hinted-subset-"acumin-pro", Arial.woff) format("woff"),
    url(../fonts/hinted-subset-"acumin-pro", Arial.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "acumin-pro", Arial;
  src: local("acumin-pro", Arial),
    url(../fonts/hinted-subset-"acumin-pro", Arial.woff2) format("woff2"),
    url(../fonts/hinted-subset-"acumin-pro", Arial.woff) format("woff"),
    url(../fonts/hinted-subset-"acumin-pro", Arial.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

*/

@mixin DesktopH1BlackLeft {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  color: $black;
}

@mixin DesktopH1BlackCentre {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: center;
  color: $black;
}

@mixin DesktopH1BlackRight {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: right;
  color: $black;
}

@mixin DesktopH1GreyLeft {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  color: $dark-grey;
}

@mixin DesktopH1GreyCentre {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH1GreyRight {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH1GoldCentre {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: center;
  color: $gold;
}

@mixin DesktopH1WhiteLeft {
    font-family: "acumin-pro";
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 44px; /* 122.222% */
    color: $white;
}

@mixin DesktopH1WhiteCentre {
    font-family: "acumin-pro";
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    color: $black;
}

@mixin DesktopH1GoldLeft {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  color: $gold;
}

@mixin DesktopH1WhiteRight {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: right;
  color: $white;
}

@mixin DesktopH1GoldRight {
  font-family: Industrial736BT-Roman;
  font-size: 40px;
  line-height: 1.3;
  text-align: right;
  color: $gold;
}

@mixin DesktopH2BlackLeft {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  color: $black;
}

@mixin DesktopH2BlackCentre {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
  color: $black;
}

@mixin DesktopH2BlackRight {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: right;
  color: $black;
}

@mixin DesktopH2GreyLeft {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  color: $dark-grey;
}

@mixin DesktopH2GreyRight {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH2GreyCentre {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH2WhiteLeft {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  color: $white;
}

@mixin DesktopH2WhiteCentre {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
  color: $white;
}

@mixin DesktopH2WhiteRight {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: right;
  color: $white;
}

@mixin DesktopH2GoldLeft {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  color: $gold;
}

@mixin DesktopH2GoldCentre {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
  color: $gold;
}

@mixin DesktopH2GoldRight {
  font-family: Industrial736BT;
  font-size: 32px;
  font-style: italic;
  line-height: 1.25;
  text-align: right;
  color: $gold;
}

@mixin DesktopH3BlackLeft {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  color: $black;
}

@mixin DesktopH3BlackCentre {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  color: $black;
}

@mixin DesktopH3BlackRight {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: right;
  color: $black;
}

@mixin DesktopH3GreyLeft {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  color: $dark-grey;
}

@mixin DesktopH3GreyCentre {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH3GreyRight {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH3WhiteLeft {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  color: $white;
}

@mixin DesktopH3WhiteCentre {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  color: $white;
}

@mixin DesktopH3WhiteRight {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: right;
  color: $white;
}

@mixin DesktopH3GoldLeft {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  color: $gold;
}

@mixin DesktopH3GoldCentre {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  color: $gold;
}

@mixin DesktopH3GoldRight {
  font-family: Industrial736BT-Roman;
  font-size: 32px;
  line-height: 1.25;
  text-align: right;
  color: $gold;
}

@mixin DesktopH4BlackLeft {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  color: $black;
}

@mixin DesktopH4BlackCentre {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $black;
}

@mixin DesktopH4BlackRight {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $black;
}

@mixin DesktopH4GreyLeft {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  color: $dark-grey;
}

@mixin DesktopH4GreyCentre {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH4GreyRight {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH4WhiteLeft {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  color: $white;
}

@mixin DesktopH4WhiteCentre {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $white;
}

@mixin DesktopH4WhiteRight {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $white;
}

@mixin DesktopH4GoldLeft {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  color: $gold;
}

@mixin DesktopH4GoldCentre {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $gold;
}

@mixin DesktopH4GoldRight {
  font-family: Industrial736BT-Roman;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $gold;
}

@mixin DesktopH5BlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  color: $black;
}

@mixin DesktopH5BlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $black;
}

@mixin DesktopH5BlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $black;
}

@mixin DesktopH5GreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  color: $dark-grey;
}

@mixin DesktopH5GreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH5GreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH5WhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  color: $black;
}

@mixin DesktopH5WhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $white;
}

@mixin DesktopH5WhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $white;
}

@mixin DesktopH5GoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  color: $gold;
}

@mixin DesktopH5GoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: right;
  color: $gold;
}

@mixin DesktopH5GoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeDemiBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---LargeDemiBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $black;
}

@mixin DesktopBody-Copy---LargeDemiBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---LargeDemiGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeDemiGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeDemiGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeDemiWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $white;
}

@mixin DesktopBody-Copy---LargeDemiWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---LargeDemiWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---LargeDemiGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeDemiGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeDemiGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  color: $black;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  color: $white;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeDemi-ItalicGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  font-style: italic;
  line-height: 1.67;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeRomanBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $black;
}

@mixin DesktopBody-Copy---LargeRomanGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeRomanBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---LargeRomanBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---LargeRomanGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeRomanGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---LargeRomanWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $white;
}

@mixin DesktopBody-Copy---LargeRomanWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---LargeRomanWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---LargeRomanGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeRomanGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---LargeRomanGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 18px;
  line-height: 1.67;
  text-align: right;
  color: $gold;
}

@mixin DesktopH5VHF-BlueLeft {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: #212b60;
}

@mixin DesktopH6BlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: $black;
}

@mixin DesktopH6BlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $black;
}

@mixin DesktopH6BlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  color: $black;
}

@mixin DesktopH6GreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: $dark-grey;
}

@mixin DesktopH6GreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH6WhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: $white;
}

@mixin DesktopH6GreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH6WhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $white;
}

@mixin DesktopH6GoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: $white;
}

@mixin DesktopH6WhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  color: $white;
}

@mixin DesktopH6GoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $gold;
}

@mixin DesktopH6GoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumDemiBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $black;
}

@mixin DesktopBody-Copy---MediumDemiBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---MediumDemiBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---MediumDemiGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumDemiGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumDemiGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumDemiWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $white;
}

@mixin DesktopBody-Copy---MediumDemiWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---MediumDemiWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---MediumDemiGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumDemiGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumDemiGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  color: $black;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  color: $white;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumDemi-ItalicGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumRomanBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.5;
  color: $black;
}

@mixin DesktopBody-Copy---MediumRomanBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---MediumRomanBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---MediumRomanGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumRomanGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumRomanGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---MediumRomanWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $white;
}

@mixin DesktopBody-Copy---MediumRomanWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---MediumRomanGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumRomanWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---MediumRomanGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---MediumRomanGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
  color: $gold;
}

@mixin DesktopH7BlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $black;
}

@mixin DesktopH7BlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: $black;
}

@mixin DesktopH7BlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: right;
  color: $black;
}

@mixin DesktopH7GreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $dark-grey;
}

@mixin DesktopH7GreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH7GreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH7WhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $white;
}

@mixin DesktopH7WhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: $white;
}

@mixin DesktopH7WhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: right;
  color: $white;
}

@mixin DesktopH7GoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: $gold;
}

@mixin DesktopH7GoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: $gold;
}

@mixin DesktopH7GoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallDemiBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---SmallDemiBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  color: $black;
}

@mixin DesktopBody-Copy---SmallDemiBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---SmallDemiGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallDemiGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallDemiGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallDemiWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  color: $white;
}

@mixin DesktopBody-Copy---SmallDemiWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---SmallDemiWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---SmallDemiGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 13px;
  line-height: 1.57;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallDemiGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallDemiGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  color: $black;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  color: $white;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: center;
  color: $white;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallDemi-ItalicGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-style: italic;
  line-height: 1.57;
  text-align: right;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallRomanBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $black;
}

@mixin DesktopBody-Copy---SmallRomanBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 13px;
  line-height: 1.57;
  color: $black;
}

@mixin DesktopBody-Copy---SmallRomanBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $black;
}

@mixin DesktopBody-Copy---SmallRomanGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallRomanGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallRomanGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopBody-Copy---SmallRomanWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 13px;
  line-height: 1.57;
  color: $black;
}

@mixin DesktopBody-Copy---SmallRomanWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallRomanWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $white;
}

@mixin DesktopBody-Copy---SmallRomanGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallRomanGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: $gold;
}

@mixin DesktopBody-Copy---SmallRomanGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  line-height: 1.57;
  text-align: right;
  color: $gold;
}

@mixin DesktopBreadcrumbBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  color: $black;
}

@mixin DesktopCTA-Heading---SmallBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  color: $black;
}

@mixin DesktopBreadcrumbBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $black;
}

@mixin DesktopBreadcrumbGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopCTA-Heading---SmallBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: $black;
}

@mixin DesktopCTA-Heading---SmallBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: $black;
}

@mixin DesktopBreadcrumbBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: $black;
}

@mixin DesktopBreadcrumbGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  color: $dark-grey;
}

@mixin DesktopCTA-Heading---SmallWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: $white;
}

@mixin DesktopCTA-Heading---SmallGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  color: $dark-grey;
}

@mixin DesktopBreadcrumbGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopCTA-Heading---SmallGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopCTA-Heading---SmallGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopCTA-Heading---SmallWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  color: $white;
}

@mixin DesktopBreadcrumbWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  color: $white;
}

@mixin DesktopBreadcrumbWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: $white;
}

@mixin DesktopCTA-Heading---SmallWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: $white;
}

@mixin DesktopBreadcrumbWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $white;
}

@mixin DesktopBreadcrumbGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  color: $gold;
}

@mixin DesktopCTA-Heading---SmallGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  color: $gold;
}

@mixin DesktopBreadcrumbGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: $gold;
}

@mixin DesktopCTA-Heading---SmallGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: right;
  color: $gold;
}

@mixin DesktopCTA-Heading---SmallGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: $gold;
}

@mixin DesktopBreadcrumbGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: $gold;
}

@mixin DesktopH8BlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  color: $black;
}

@mixin DesktopH8BlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: $black;
}

@mixin DesktopH8BlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: right;
  color: $black;
}

@mixin DesktopH8GreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  color: $dark-grey;
}

@mixin DesktopH8GreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: $dark-grey;
}

@mixin DesktopH8WhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  color: $white;
}

@mixin DesktopH8GreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: right;
  color: $dark-grey;
}

@mixin DesktopH8WhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: right;
  color: $white;
}

@mixin DesktopH8WhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: $white;
}

@mixin DesktopH8GoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  color: $gold;
}

@mixin DesktopH8GoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: center;
  color: $gold;
}

@mixin DesktopH8GoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  text-align: right;
  color: $gold;
}

@mixin MobileH5WhiteLeft {
    font-family: "acumin-pro", Arial;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: $black;
}

@mixin MobileH2GreyLeft {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  color: $dark-grey;
}

@mixin MobileH2BlackRight {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: $black;
}

@mixin MobileH2GreyRight {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: $dark-grey;
}

@mixin MobileH2WhiteLeft {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  color: $white;
}

@mixin MobileH2GoldLeft {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  color: $gold;
}

@mixin MobileH2WhiteRight {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: $white;
}

@mixin MobileH2GreyCentre {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $dark-grey;
}

@mixin MobileH2WhiteCentre {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $white;
}

@mixin MobileH2GoldRight {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: $gold;
}

@mixin MobileH2BlackLeft {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  color: $black;
}

@mixin MobileH2GoldCentre {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $gold;
}

@mixin MobileH2BlackCentre {
  font-family: Industrial736BT;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $black;
}



@mixin MobileH3WhiteCentre {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: $white;
}

@mixin MobileH3WhiteRight {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: right;
  color: $white;
}

@mixin MobileH4WhiteLeft {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: $white;
}

@mixin MobileH3GreyCentre {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: $dark-grey;
}

@mixin MobileH3GoldRight {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: right;
  color: $gold;
}

@mixin MobileH4GoldLeft {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: $gold;
}

@mixin MobileH3GreyRight {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: right;
  color: $dark-grey;
}

@mixin MobileH3GreyLeft {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: $dark-grey;
}

@mixin MobileH3BlackRight {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: right;
  color: $black;
}

@mixin MobileH3GoldCentre {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: $gold;
}

@mixin MobileH4BlackLeft {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: $black;
}

@mixin MobileH4BlackCentre {
  font-family: Industrial736BT-Roman;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: $black;
}



@mixin MobileCTA-Heading---LargeGoldLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $gold;
}

@mixin MobileCTA-Heading---LargeGoldRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: $gold;
}

@mixin MobileCTA-Heading---LargeWhiteRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: $white;
}

@mixin MobileCTA-Heading---LargeGoldCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $gold;
}

@mixin MobileCTA-Heading---LargeBlackCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $black;
}

@mixin MobileCTA-Heading---LargeGreyCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $dark-grey;
}

@mixin MobileCTA-Heading---LargeBlackRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: $black;
}

@mixin MobileCTA-Heading---LargeBlackLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $black;
}

@mixin MobileCTA-Heading---LargeWhiteLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $white;
}

@mixin MobileCTA-Heading---LargeGreyLeft {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $dark-grey;
}

@mixin MobileCTA-Heading---LargeGreyRight {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: $dark-grey;
}

@mixin MobileCTA-Heading---LargeWhiteCentre {
  font-family: "acumin-pro", Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: $white;
}
